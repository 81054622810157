import { Props, ToC, Placeholder, ComponentExample, Features, Feature, RelatedComponents } from 'docs';
import { Stack } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Props,
  ToC,
  Placeholder,
  ComponentExample,
  Features,
  Feature,
  RelatedComponents,
  Stack,
  React
};