import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'
import color from '@pleo-io/telescope-tokens/dist/color.json'

import {
    Table,
    TableHead,
    TableHeadRow,
    TableHeadData,
    TableBody,
    TableBodyRow,
    TableBodyData,
    TokenName
} from '@/components/docs/table'

const Example = styled.div<{backgroundColor: string}>`
    height: 20px;
    background-color: ${({backgroundColor}) => backgroundColor};
    border-radius: ${tokens.arc4};
`

export const Color = () => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Value</TableHeadData>
                    <TableHeadData>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {color.props.map(({name, value}) => (
                    <TableBodyRow key={name}>
                        <TableBodyData>
                            <TokenName name={name} />
                        </TableBodyData>
                        <TableBodyData>{value}</TableBodyData>
                        <TableBodyData>
                            <Example backgroundColor={value} />
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default Color
