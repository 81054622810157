export const opacity01 = 0.01
export const opacity05 = 0.05
export const opacity10 = 0.1
export const opacity20 = 0.2
export const opacity30 = 0.3
export const opacity40 = 0.4
export const opacity50 = 0.5
export const opacity60 = 0.6
export const opacity70 = 0.7
export const opacity80 = 0.8
export const opacity90 = 0.9
