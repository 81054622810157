import { Filter, Help } from '@pleo-io/telescope-icons';
import { ActionMenu, Avatar, AvatarGroup, Box, Button, Drawer, IconButton, Inline, Link, Modal, NakedButton, Popover, ProgressBar, Stack, Text, Input, Tabs } from '@pleo-io/telescope';
import { Example } from 'docs';
import * as React from 'react';
export default {
  Filter,
  Help,
  ActionMenu,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Drawer,
  IconButton,
  Inline,
  Link,
  Modal,
  NakedButton,
  Popover,
  ProgressBar,
  Stack,
  Text,
  Input,
  Tabs,
  Example,
  React
};