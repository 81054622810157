import React from 'react'
import styled from 'styled-components'

import {Input, tokens} from '@pleo-io/telescope'
import {Search} from '@pleo-io/telescope-icons'

type Props = {
    value: string
    onChange: (value: string) => void
    placeholder: string
    name: string
}

const Wrapper = styled(Input)`
    min-width: 180px;
`

export const SearchInput = ({value, placeholder, name, onChange, ...rest}: Props) => (
    <Wrapper
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
        name={name}
        type="search"
        placeholder={placeholder}
        // @ts-ignore There's a type issue in the Input component it seems
        prefix={
            <Search
                style={{
                    color: tokens.colorContentInteractiveQuiet,
                    marginRight: '4px',
                    display: 'flex'
                }}
                size={16}
            />
        }
        {...rest}
    />
)
