import { Box } from '@pleo-io/telescope';
import { Props, RawPropsTable, Features, Feature, Placeholder, ComponentExample, RelatedComponents } from 'docs';
import { tokens } from '@pleo-io/telescope';
import { Table, TableHead, TableHeadRow, TableHeadData, TableBody, TableBodyData, TableBodyRow } from '@/components/docs/table';
import * as React from 'react';
export default {
  Box,
  Props,
  RawPropsTable,
  Features,
  Feature,
  Placeholder,
  ComponentExample,
  RelatedComponents,
  tokens,
  Table,
  TableHead,
  TableHeadRow,
  TableHeadData,
  TableBody,
  TableBodyData,
  TableBodyRow,
  React
};