import { Example } from 'docs';
import { Labels, Placeholders, HintText, ErrorMessages, ShortFormValidation, SubmitButtons, RequiredAndOptionalFields } from '@/components/docs/patterns/forms';
import { FormControl, Input, Button, Stack, Link, Text } from '@pleo-io/telescope';
import { Attention } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Example,
  Labels,
  Placeholders,
  HintText,
  ErrorMessages,
  ShortFormValidation,
  SubmitButtons,
  RequiredAndOptionalFields,
  FormControl,
  Input,
  Button,
  Stack,
  Link,
  Text,
  Attention,
  React
};