import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'
import border from '@pleo-io/telescope-tokens/dist/border.json'

import {
    Table,
    TableHead,
    TableHeadRow,
    TableHeadData,
    TableBody,
    TableBodyRow,
    TableBodyData,
    TokenName
} from '@/components/docs/table'

const Example = styled.div<{border: string}>`
    height: ${tokens.spacing20};
    border: ${({border}) => border};
    border-radius: ${tokens.arc4};
`

export const Border = () => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Value</TableHeadData>
                    <TableHeadData>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {border.props.map(({name, value}) => (
                    <TableBodyRow key={name}>
                        <TableBodyData>
                            <TokenName name={name} />
                        </TableBodyData>
                        <TableBodyData>{value}</TableBodyData>
                        <TableBodyData>
                            <Example border={value} />
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default Border
