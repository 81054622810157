export const spacing2 = '2px'
export const spacing4 = '4px'
export const spacing6 = '6px'
export const spacing8 = '8px'
export const spacing10 = '10px'
export const spacing12 = '12px'
export const spacing14 = '14px'
export const spacing16 = '16px'
export const spacing18 = '18px'
export const spacing20 = '20px'
export const spacing24 = '24px'
export const spacing28 = '28px'
export const spacing32 = '32px'
export const spacing36 = '36px'
export const spacing40 = '40px'
export const spacing44 = '44px'
export const spacing48 = '48px'
export const spacing52 = '52px'
export const spacing56 = '56px'
export const spacing60 = '60px'
