import { ComponentExample, Features, Feature, RawPropsTable, RelatedComponents } from 'docs';
import { Select, BaseError } from '@pleo-io/telescope';
import { CategoryNone, CategoryTravel, CategoryEquipment, CategoryEntertainment, User, Tag } from '@pleo-io/telescope-icons';
import jeppe from '@/assets/jeppe.png';
import niccolo from '@/assets/niccolo.png';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  RawPropsTable,
  RelatedComponents,
  Select,
  BaseError,
  CategoryNone,
  CategoryTravel,
  CategoryEquipment,
  CategoryEntertainment,
  User,
  Tag,
  jeppe,
  niccolo,
  React
};