import { Features, Feature, ComponentExample, RelatedComponents } from 'docs';
import { Stack, Text, Link, Inline, Button, NakedButton, Box, Popover, tokens } from '@pleo-io/telescope';
import { Info } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  Stack,
  Text,
  Link,
  Inline,
  Button,
  NakedButton,
  Box,
  Popover,
  tokens,
  Info,
  React
};