import { Props } from 'docs';
import { FormControl, Input } from '@pleo-io/telescope';
import { CategoryEntertainment, SpeechBubble, Wallet, MinusCircle } from '@pleo-io/telescope-icons';
import dollarsign from '@/assets/dollarsign.svg';
import jeppe from '@/assets/jeppe.png';
import niccolo from '@/assets/niccolo.png';
import * as React from 'react';
export default {
  Props,
  FormControl,
  Input,
  CategoryEntertainment,
  SpeechBubble,
  Wallet,
  MinusCircle,
  dollarsign,
  jeppe,
  niccolo,
  React
};