import chroma from 'chroma-js'
import React, {useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import styled from 'styled-components'

import {
    Badge,
    Box,
    Checkbox,
    Inline,
    Input,
    NakedButton,
    Popover,
    Stack,
    Text
} from '@pleo-io/telescope'
import {tokens} from '@pleo-io/telescope'
import {ChevronDown, ChevronUp, Copy, Help, Search} from '@pleo-io/telescope-icons'
import colorSchemeDark from '@pleo-io/telescope-tokens/dist/color-scheme-dark.json'
import colorSchemeLight from '@pleo-io/telescope-tokens/dist/color-scheme-light.json'
import color from '@pleo-io/telescope-tokens/dist/color.json'

import {ThemeName, useThemeSwitcher} from '@/components/theme-switcher/theme-switcher'
import useFuse from '@/hooks/use-fuse'

const tokenExampleSize = '70px'

const camelCaseToNormalCase = (string: string) =>
    string
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, (str) => str.toUpperCase())

const formatName = (token: Omit<TokenType, 'title'>) => {
    const name = token.type ? token.name.replace(token.category, '') : token.name
    return camelCaseToNormalCase(name).trim()
}

type TokenType = {
    name: string
    title: string
    value: string
    rawValue: string
    category: string
    description?: string
    type?: string
    exampleBackgroundToken?: string
    experimental?: boolean
}

const TokenWrapper = styled.div`
    width: 100%;
    padding: ${tokens.spacing24};
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
    border-radius: ${tokens.arc12};
`

const TokenInner = styled.div`
    display: flex;
    align-items: flex-start;
`

const StyledInput = styled(Input)`
    min-width: 180px;
`

const Header = styled(Inline).attrs({space: 12})`
    align-items: center;

    > :first-child {
        flex: 1;
    }
`

const getBackgroundToken = (token: TokenType, tokens: TokenType[]) => {
    const backgroundToken = tokens.find((t) => t.name === token.exampleBackgroundToken)

    if (!backgroundToken) {
        throw new Error(`No token with exampleBackgroundToken: ${token.exampleBackgroundToken}`)
    }

    return backgroundToken
}

const TokenBase = styled.div`
    flex: 0 0 ${tokenExampleSize};
    height: ${tokenExampleSize};
    border-radius: ${tokens.arc8};
`

const TokenColorBackground = styled(TokenBase)<{token: TokenType}>`
    background-color: ${({token}) => token.value};
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
`

const TokenColorContent = styled(TokenBase)<{token: TokenType; background: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({token}) => token.value};
    font-size: ${tokens.font3XLarge};
    background: ${({background}) => background};
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
`

const TokenColorBorder = styled(TokenBase)<{token: TokenType}>`
    border: 3px solid ${({token}) => token.value};
`

const TokenColorShadow = styled(TokenBase)<{token: TokenType}>`
    box-shadow: 0 0 ${tokens.spacing12} ${({token}) => token.value};
`

const TokenShadow = styled(TokenBase)<{token: TokenType}>`
    box-shadow: ${({token}) => token.value};
`

const TokenVisualisation = ({token, allTokens}: {token: TokenType; allTokens: TokenType[]}) => {
    if (token.type === 'border') {
        return <TokenColorBorder token={token} />
    } else if (token.type === 'shadow') {
        return <TokenColorShadow token={token} />
    } else if (token.type === 'content') {
        const backgroundToken = getBackgroundToken(token, allTokens)
        return (
            <TokenColorContent token={token} background={backgroundToken?.value}>
                Aa
            </TokenColorContent>
        )
    } else if (token.category === 'shadow') {
        return <TokenShadow token={token} />
    }

    return <TokenColorBackground token={token} />
}

const tokenInfoMarginLeft = tokens.spacing24
const TokenInfo = styled.div`
    margin-right: auto;
    margin-left: ${tokenInfoMarginLeft};
`

const TokenName = styled.div`
    color: ${tokens.colorContentStatic};
    font-weight: ${tokens.fontWeightBold};
    font-size: ${tokens.fontLarge};
`

const TokenDescription = styled.div`
    display: -webkit-box;
    max-width: 60ch;
    overflow: hidden;
    color: ${tokens.colorContentStaticQuiet};
    font-size: ${tokens.fontMedium};
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const TokenValues = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: ${tokens.spacing24};

    > *:not(:last-child) {
        margin-bottom: ${tokens.spacing2};
    }
`

const TokenValueButton = styled(NakedButton)`
    display: flex;
    align-items: center;
    color: ${tokens.colorContentInteractiveQuiet};
    font-size: ${tokens.fontMedium};
    white-space: nowrap;

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }

    svg {
        margin-left: ${tokens.spacing4};
    }
`

const TokenValue = ({token}: {token: TokenType}) => {
    const [didCopy, setDidCopy] = useState(false)
    const onCopy = () => {
        setDidCopy(true)
        setTimeout(() => setDidCopy(false), 1000)
    }
    return (
        <CopyToClipboard text={`tokens.${token.name}`} onCopy={onCopy}>
            <TokenValueButton>
                {didCopy ? 'You got it!' : 'Get code'}
                <Copy size={16} />
            </TokenValueButton>
        </CopyToClipboard>
    )
}

const TokenDetailsButton = styled(NakedButton)`
    color: ${tokens.colorContentInteractiveQuiet};
    font-size: ${tokens.fontMedium};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }

    svg {
        margin-left: ${tokens.spacing4};
    }
`

const TokenContrastText = styled(Text)<{contrastScore: number}>`
    color: ${({contrastScore}) => {
        if (contrastScore > 4.5) {
            return tokens.colorContentPositive
        }

        return tokens.colorContentNegative
    }};
`

const TokenContrastButton = styled(NakedButton)`
    display: flex;
    color: ${tokens.colorContentInteractiveQuiet};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }
`

const TokenContrast = ({token, allTokens}: {token: TokenType; allTokens: TokenType[]}) => {
    const contrastToken = getBackgroundToken(token, allTokens)
    const contrastScore = Number(
        chroma.contrast(token.rawValue, contrastToken?.rawValue).toFixed(2)
    )

    return (
        <Inline space={4} alignItems="center">
            <TokenContrastText contrastScore={contrastScore}>AA {contrastScore}</TokenContrastText>
            <Popover>
                <Popover.Trigger>
                    <TokenContrastButton>
                        <Help size={16} />
                    </TokenContrastButton>
                </Popover.Trigger>
                <Popover.Content>
                    <Popover.Arrow />
                    <Box p={12} css={{maxWidth: 400}}>
                        <Text variant="medium-default">
                            <code>{token.name}</code> when rendered on top of{' '}
                            <code>{contrastToken?.name}</code>. A{' '}
                            <a
                                target="_blank"
                                href="https://www.w3.org/TR/WCAG20-TECHS/G18.html"
                                rel="noreferrer"
                            >
                                minimum score of 4.5
                            </a>{' '}
                            is our target.
                        </Text>
                    </Box>
                </Popover.Content>
            </Popover>
        </Inline>
    )
}

const TokenDetails = styled.div`
    margin-top: ${tokens.spacing24};
    padding-top: ${tokens.spacing24};
    padding-left: calc(${tokenExampleSize} + ${tokenInfoMarginLeft});
    border-top: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
`

const TokenDetailWrapper = styled(Inline).attrs({space: 4})`
    margin-bottom: ${tokens.spacing4};
`

const TokenDetailLabel = styled(Text)`
    min-width: 200px;
    color: ${tokens.colorContentStatic};
`

const TokenDetailValue = styled(Text)`
    color: ${tokens.colorContentStaticQuiet};
`

const TokenDetail = ({label, value}: {label: string; value: any}) => (
    <TokenDetailWrapper>
        <TokenDetailLabel>{label}:</TokenDetailLabel>
        <TokenDetailValue>{value}</TokenDetailValue>
    </TokenDetailWrapper>
)

const Token = ({
    token,
    allTokens,
    showAllDetails
}: {
    token: TokenType
    allTokens: TokenType[]
    showAllDetails: boolean
}) => {
    const [showDetails, setShowDetails] = useState(showAllDetails)
    const referees = allTokens.filter((t) => !!t.value.match(token.name))

    const toggleShowDetails = () => {
        setShowDetails(!showDetails)
    }

    useEffect(() => {
        setShowDetails(showAllDetails)
    }, [showAllDetails])

    return (
        <TokenWrapper>
            <TokenInner>
                <TokenVisualisation token={token} allTokens={allTokens} />
                <TokenInfo>
                    <Inline space={4} marginBottom={4}>
                        <TokenName>{token.title}</TokenName>
                        {token.experimental && <Badge variant="warning">Experimental</Badge>}
                    </Inline>

                    <TokenDescription>
                        {token.description || 'No description available'}
                    </TokenDescription>
                </TokenInfo>
                <TokenValues>
                    {token.type === 'content' && (
                        <TokenContrast token={token} allTokens={allTokens} />
                    )}
                    <TokenValue token={token} />
                    <TokenDetailsButton onClick={toggleShowDetails}>
                        Details{showDetails ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                    </TokenDetailsButton>
                </TokenValues>
            </TokenInner>
            {showDetails && (
                <TokenDetails>
                    <TokenDetail label="Value" value={token.value} />
                    <TokenDetail label="Raw value" value={token.rawValue} />
                    <TokenDetail
                        label="Referenced by tokens"
                        value={
                            referees.length
                                ? referees.map((referee) => <div>{referee.name}</div>)
                                : 'None'
                        }
                    />
                </TokenDetails>
            )}
        </TokenWrapper>
    )
}

const Tokens = ({
    filteredTokens,
    allTokens,
    showAllDetails
}: {
    filteredTokens: TokenType[]
    allTokens: TokenType[]
    showAllDetails: boolean
}) => (
    <Stack space={18}>
        {filteredTokens.map((token) => (
            <Token token={token} allTokens={allTokens} showAllDetails={showAllDetails} />
        ))}
    </Stack>
)

const getSchemeColors = (theme: ThemeName) => {
    if (theme === 'light') {
        return colorSchemeLight.props
    }

    return colorSchemeDark.props.map((token) => {
        const lightVersion = colorSchemeLight.props.find((t) => t.name === token.name)
        return {...lightVersion, ...token}
    })
}

export const Color = () => {
    const {theme} = useThemeSwitcher()
    const schemeColors = getSchemeColors(theme)
    const [showAllDetails, setShowAllDetails] = useState(false)

    const allTokens = [...schemeColors, ...color.props].map((token) => ({
        ...token,
        title: formatName(token)
    }))

    const {result, search, term} = useFuse<TokenType>(allTokens, {
        keys: [
            // 1st) - shade800
            {name: 'value', weight: 0.8},

            // 2nd) - #222222
            {name: 'rawValue', weight: 0.5},

            // 3rd) - Content Interactive
            {name: 'title', weight: 0.4},

            // 4th) - Default colour for text and icons...
            {name: 'description', weight: 0.3}
        ]
    })

    return (
        <Stack space={24} stretch>
            <Header>
                <StyledInput
                    postfix={<Search size={18} />}
                    name="search"
                    type="search"
                    onChange={(e) => search(e.target.value)}
                    value={term}
                    placeholder="Search tokens..."
                />
                <Checkbox
                    checked={showAllDetails}
                    onChange={() => setShowAllDetails(!showAllDetails)}
                >
                    Show details for all tokens
                </Checkbox>
            </Header>
            <Tokens filteredTokens={result} allTokens={allTokens} showAllDetails={showAllDetails} />
        </Stack>
    )
}
