import { ComponentExample, Features, Feature, RelatedComponents, DosAndDonts } from 'docs';
import { Card, Button, Text } from '@pleo-io/telescope';
import { UserPlus } from '@pleo-io/telescope-icons';
import spendtimebetter from '@/assets/spendtimebetter.svg';
import securitylock from '@/assets/securitylock.svg';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  RelatedComponents,
  DosAndDonts,
  Card,
  Button,
  Text,
  UserPlus,
  spendtimebetter,
  securitylock,
  React
};