import { BeginningAndEndingOnScreen, EnterAndExit, SizeExample, DistanceExample, StaggeredAnimations } from '@/components/docs/foundations/motion';
import { Example } from 'docs';
import * as React from 'react';
export default {
  BeginningAndEndingOnScreen,
  EnterAndExit,
  SizeExample,
  DistanceExample,
  StaggeredAnimations,
  Example,
  React
};