import { Props } from 'docs';
import { Tooltip, NakedButton } from '@pleo-io/telescope';
import { Download } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Props,
  Tooltip,
  NakedButton,
  Download,
  React
};