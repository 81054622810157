import { Props } from 'docs';
import { Avatar, AvatarGroup } from '@pleo-io/telescope';
import jeppe from '@/assets/jeppe.png';
import niccolo from '@/assets/niccolo.png';
import * as React from 'react';
export default {
  Props,
  Avatar,
  AvatarGroup,
  jeppe,
  niccolo,
  React
};