export const borderDark = '1px solid #333333'
export const borderDarkest = '1px solid #000000'
export const borderError = '1px solid #e91c1c'
export const borderLight = '1px solid #f5f5f5'
export const borderLightest = '1px solid #ffffff'
export const borderPrimary = '1px solid #ececec'
export const borderSuccess = '1px solid #85d69e'
export const sizeBorderDefault = '1px'
export const sizeBorderThick = '2px'
export const sizeBorderThickest = '3px'
