import React from 'react'
import styled, {css} from 'styled-components'

import {tokens} from '@pleo-io/telescope'

export const Table = styled.table<{$fluid?: boolean}>`
    ${({$fluid}) =>
        !$fluid &&
        css`
            width: 100%;
        `};
    margin: ${tokens.spacing36} 0 ${tokens.spacing36} 0;
    width: 100%;
    border-collapse: collapse;
`

export const TableHead = styled.thead`
    text-align: left;
`

export const TableHeadRow = styled.tr`
    border-bottom: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
`

export const TableHeadData = styled.th`
    padding-right: ${tokens.spacing12};
    padding-bottom: ${tokens.spacing12};
    padding-left: ${tokens.spacing12};
    color: ${tokens.colorContentStatic};
    font-weight: ${tokens.fontWeightSemibold};
    font-size: ${tokens.fontMedium};
    border-bottom: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
`

export const TableBody = styled.tbody``

export const TableBodyRow = styled.tr`
    padding-top: ${tokens.spacing4};
    padding-bottom: ${tokens.spacing4};
`

// TODO: Need to double check the color property and its token
export const TableBodyData = styled.td<{nowrap?: boolean}>`
    padding: ${tokens.spacing14};
    color: ${tokens.colorContentStatic};
    font-weight: ${tokens.fontWeightRegular};
    font-size: ${tokens.fontMedium};
    white-space: ${({nowrap}) => (nowrap ? 'nowrap' : 'normal')};
    border-bottom: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
    vertical-align: top;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
`

export const TablePill = styled.code`
    display: inline-block;
    margin-bottom: ${tokens.spacing4};
    background-color: ${tokens.colorBackgroundPresentationalPink};
`

export const TokenName = ({name, deprecated}: {name: string; deprecated?: boolean}) => (
    <>
        <code>{name}</code>
        {deprecated && <> (Deprecated)</>}
    </>
)
