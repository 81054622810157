import { Features, Feature, ComponentExample, RelatedComponents, DosAndDonts } from 'docs';
import { ActionMenu, Avatar, AvatarGroup, Box, Button, Drawer, FormControl, Inline, ProgressBar, Stack, Text, Input, Tabs } from '@pleo-io/telescope';
import jeppe from '@/assets/jeppe.png';
import niccolo from '@/assets/niccolo.png';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  DosAndDonts,
  ActionMenu,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Drawer,
  FormControl,
  Inline,
  ProgressBar,
  Stack,
  Text,
  Input,
  Tabs,
  jeppe,
  niccolo,
  React
};