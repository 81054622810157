import { Props } from 'docs';
import { ActionMenu, IconButton } from '@pleo-io/telescope';
import { Options, Download } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Props,
  ActionMenu,
  IconButton,
  Options,
  Download,
  React
};