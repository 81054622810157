import {color, duration, opacity, radius, transition} from '@pleo-io/telescope-tokens'

// RGB
export const rgbColor = {
    shade900rgb: '0, 0, 0', // used for shadows and backdrops
    pink400rgb: '255, 200, 208', // used for the loader
    yellow700rgb: '223, 197, 56' // used for the yellow card
}

// Theme
export const theme = {
    colors: color,
    duration,
    opacity,
    radius,
    rgbColor,
    transition,
    /**
     * Avoid index for spaces, use directly the numeric value
     * https://styled-system.com/demo#space-theming
     */
    space: [0]
}
