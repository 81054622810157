import { FilterMenu, FilterResults } from '@pleo-io/telescope';
import { Example } from 'docs';
import FiltersLayoutImage from "../../../../pages/patterns/filtering/filterslayout.png";
import OrderingImage from "../../../../pages/patterns/filtering/ordering.png";
import * as React from 'react';
export default {
  FilterMenu,
  FilterResults,
  Example,
  FiltersLayoutImage,
  OrderingImage,
  React
};