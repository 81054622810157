import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {minWidthTOC} from '../layout/global-styles'

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${tokens.spacing24};

    @media (width >= 600px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${minWidthTOC}) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`
