import {useReducedMotion} from 'framer-motion'
import Lottie from 'lottie-react'
import React from 'react'

import {CardStatic, useCardStatic} from '../card-static'
import {OverviewPage} from '../overview-page'

type AnimationType = {
    displayName: string
    json: any
}

const importAllAnimations = (requireContext: any): AnimationType[] =>
    requireContext.keys().map((filePath: string) => {
        const displayName = filePath.replace('./', '').replace('.json', '')
        return {displayName, json: requireContext(filePath)}
    })

const animations = importAllAnimations(
    // @ts-ignore: TypeScript does not recognize Webpack's require.context
    // method because it is not part of the standard TypeScript types.
    require.context('@pleo-io/telescope-animated-illustrations/lottie', false, /\.json$/)
)

function getDisplayName(str: string) {
    return str.replace(/-./g, (match) => match.charAt(1).toUpperCase())
}

const getCode = (name: string) => {
    const displayName = getDisplayName(name)
    return `
import Lottie from 'lottie-react'
import {useReducedMotion} from 'framer-motion'
import ${displayName} from '@pleo-io/telescope-animated-illustrations/lottie/${name}.json'

const MyComponent = () => {
    const shouldReduceMotion = useReducedMotion()
    return <Lottie animationData={${displayName}} autoplay={!shouldReduceMotion} loop />
}
`
}

const Animation = ({data}: any) => {
    const shouldReduceMotion = useReducedMotion()
    return <Lottie autoplay={!shouldReduceMotion} animationData={data} style={{display: 'flex'}} />
}

export const AnimatedList = () => {
    const {filterValue, handleFilterChange, filteredList, location} = useCardStatic({
        list: animations
    })
    return (
        <OverviewPage>
            <OverviewPage.Search
                name="pictograms-search"
                placeholder="Search illustrations..."
                value={filterValue}
                onChange={handleFilterChange}
            />
            <OverviewPage.Cards>
                {filteredList.map(({displayName, json}, index) => {
                    return (
                        <CardStatic key={index}>
                            <CardStatic.CopyButton text={getCode(displayName)} />
                            <CardStatic.Asset>
                                <Animation data={json} />
                            </CardStatic.Asset>
                            <CardStatic.Name location={location}>{displayName}</CardStatic.Name>
                        </CardStatic>
                    )
                })}
            </OverviewPage.Cards>
        </OverviewPage>
    )
}
