import { EmotionSmiley } from '@pleo-io/telescope-icons';
import lightbulb from '@/assets/lightbulb.svg';
import { Linkbox, FoundationsIllustration } from 'docs';
import { tokens } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  EmotionSmiley,
  lightbulb,
  Linkbox,
  FoundationsIllustration,
  tokens,
  React
};