/**
 * @deprecated Use 'fontSmall' instead
 */
export const fontXSmall = '10px'
export const fontSmall = '12px'
export const fontMedium = '14px'
export const fontLarge = '16px'
export const fontXLarge = '18px'
export const font2XLarge = '20px'
export const font3XLarge = '24px'
export const font4XLarge = '32px'
export const font5XLarge = '48px'
