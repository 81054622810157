import React from 'react'

import tokens from '@pleo-io/telescope-tokens'

export const Column = ({children}: {children: React.ReactNode}) => (
    <div css={{flex: 1}}>{children}</div>
)

export const Columns = ({children}: {children: React.ReactNode}) => (
    <div
        css={{
            display: 'flex',
            gap: tokens.spacing24,
            marginBlock: tokens.spacing36
        }}
    >
        {children}
    </div>
)
