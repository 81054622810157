import React from 'react'

import {Inline, tokens} from '@pleo-io/telescope'
import * as icons from '@pleo-io/telescope-icons'

import {CardStatic, useCardStatic} from '../card-static'
import {OverviewPage} from '../overview-page'

const getCode = (name: string) => {
    return `
import {${name}} from '@pleo-io/telescope-icons'

const MyComponent = () => <${name} />
`
}

interface IconMeta {
    ({color, size, ...props}: icons.Props): JSX.Element
    displayName: string
}

export const IconsList = () => {
    const iconsList = Object.values(icons) as IconMeta[]
    const {filterValue, handleFilterChange, filteredList, location} = useCardStatic<IconMeta>({
        list: iconsList
    })

    return (
        <OverviewPage>
            <OverviewPage.Search
                name="icon-search"
                placeholder="Search icons..."
                value={filterValue}
                onChange={handleFilterChange}
            />
            <OverviewPage.Cards>
                {filteredList.map((Icon) => {
                    return (
                        <CardStatic key={Icon.displayName} paddingBlock={tokens.spacing52}>
                            <CardStatic.CopyButton text={getCode(Icon.displayName)} />
                            <CardStatic.Asset>
                                <Inline space={12} alignItems="center" justifyContent="center">
                                    <Icon size={16} />
                                    <Icon size={32} />
                                    <Icon size={24} />
                                </Inline>
                            </CardStatic.Asset>
                            <CardStatic.Name location={location}>
                                {Icon.displayName}
                            </CardStatic.Name>
                        </CardStatic>
                    )
                })}
            </OverviewPage.Cards>
        </OverviewPage>
    )
}
