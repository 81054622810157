/**
 * @description The easing curve for motion which happens within the screen (e.g. an accordion expanding and contracting).
 */
export const motionEasingWithinScreen = 'ease-in-out'

/**
 * @description The easing curve for motion applied to an element that enters the screen (e.g. a drawer sliding in from the side of the screen).
 */
export const motionEasingEnterScreen = 'ease-out'

/**
 * @description The easing curve for motion applied to an element that exits the screen (e.g. a drawer sliding out to the side of the screen).
 */
export const motionEasingExitScreen = 'ease-in'

/**
 * @description The base value for motion durations.
 */
export const motionDurationStep = 0.12

/**
 * @description The duration value for fast motion.
 */
export const motionDurationFast = 0.12

/**
 * @description The duration value for moderate motion.
 */
export const motionDurationModerate = 0.24

/**
 * @description The duration value for slow motion.
 */
export const motionDurationSlow = 0.36

/**
 * @description The duration value for extraSlow motion.
 */
export const motionDurationExtraSlow = 0.48

/**
 * @description The easing and duration for motion happening to a small element over a short distance within the screen.
 */
export const motionWithinSmallShort = '0.12s ease-in-out'

/**
 * @description The easing and duration for motion happening to a small element over a long distance within the screen.
 */
export const motionWithinSmallLong = '0.24s ease-in-out'

/**
 * @description The easing and duration for motion happening to a large element over a short distance within the screen.
 */
export const motionWithinLargeShort = '0.12s ease-in-out'

/**
 * @description The easing and duration for motion happening to a large element over a long distance within the screen.
 */
export const motionWithinLargeLong = '0.36s ease-in-out'

/**
 * @description The easing and duration for motion happening to a small element over a short distance when entering the screen.
 */
export const motionEnterSmallShort = '0.24s ease-out'

/**
 * @description The easing and duration for motion happening to a small element over a long distance when entering the screen.
 */
export const motionEnterSmallLong = '0.36s ease-out'

/**
 * @description The easing and duration for motion happening to a large element over a short distance when entering the screen.
 */
export const motionEnterLargeShort = '0.36s ease-out'

/**
 * @description The easing and duration for motion happening to a large element over a long distance when entering the screen.
 */
export const motionEnterLargeLong = '0.48s ease-out'

/**
 * @description The easing and duration for motion happening to a small element over a short distance when exiting the screen.
 */
export const motionExitSmallShort = '0.12s ease-in'

/**
 * @description The easing and duration for motion happening to a small element over a long distance when exiting the screen.
 */
export const motionExitSmallLong = '0.24s ease-in'

/**
 * @description The easing and duration for motion happening to a large element over a short distance when exiting the screen.
 */
export const motionExitLargeShort = '0.24s ease-in'

/**
 * @description The easing and duration for motion happening to a large element over a long distance when exiting the screen.
 */
export const motionExitLargeLong = '0.36s ease-in'
