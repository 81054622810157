import {useStaticQuery, graphql} from 'gatsby'

import {IllustrationsImagesQuery} from '@/graphql-types'

export const useIllustrationsImages = () => {
    const data = useStaticQuery<IllustrationsImagesQuery>(graphql`
        query IllustrationsImages {
            allFile(filter: {absolutePath: {regex: "/src/assets/illustrations/"}}) {
                edges {
                    node {
                        relativePath
                        publicURL
                        name
                    }
                }
            }
        }
    `)

    return data.allFile.edges.reduce((acc: Record<string, string>, edge) => {
        acc[edge.node.name] = edge.node.publicURL
        return acc
    }, {})
}
