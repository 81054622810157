import { Features, Feature, ComponentExample, DosAndDonts, RelatedComponents } from 'docs';
import { Tooltip, IconButton, Button, Stack, Text, NakedButton } from '@pleo-io/telescope';
import { Download, Bell } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  DosAndDonts,
  RelatedComponents,
  Tooltip,
  IconButton,
  Button,
  Stack,
  Text,
  NakedButton,
  Download,
  Bell,
  React
};