import {useStaticQuery, graphql} from 'gatsby'
import React from 'react'
import styled, {keyframes} from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'

import {DesignTokensQuery} from '@/graphql-types'
import {sort} from '@/utilities/sort'

import {Card} from './card'
import {Grid} from './list-grid'

type Token = {
    path: string
    title: string
    intro: string
}

const Illustration = styled(Card.Illustration)`
    background-image: radial-gradient(${tokens.colorContentStaticQuiet} 0.5px, transparent 0.5px),
        radial-gradient(
            ${tokens.colorContentStaticQuiet} 0.5px,
            ${tokens.colorBackgroundStaticLoud} 0.5px
        );
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    opacity: 0.8;
`

const BorderExample = styled.div`
    flex: 1;
    height: 2px;
    background-color: ${tokens.colorBorderInteractiveLoud};
    border-radius: ${tokens.arc99999};
`
const Border = () => <BorderExample />

const ColorExample = styled.div<{color: string}>`
    flex: 1;
    height: 40px;
    background-color: ${({color}) => color};
`

const Colors = styled.div`
    display: flex;
    flex: 1;
    height: 40px;
    overflow: hidden;
    border-radius: ${tokens.arc8};
`

const Color = () => (
    <Colors>
        <ColorExample color={tokens.pink200} />
        <ColorExample color={tokens.pink300} />
        <ColorExample color={tokens.pink400} />
        <ColorExample color={tokens.pink500} />
    </Colors>
)

const Shadow = styled.div`
    flex: 50%;
    height: 40px;
    background-color: ${tokens.colorBackgroundStatic};
    border-radius: ${tokens.arc8};
    box-shadow: ${tokens.shadowRaise};
`

const Typography = () => (
    <Text variant="4xlarge-accent" weight="bold">
        Aa
    </Text>
)

const OpacityExample = styled.div<{opacity: number}>`
    width: 40px;
    height: 40px;
    background-color: ${tokens.colorContentStatic};
    border-radius: ${tokens.arc99999};
    opacity: ${({opacity}) => opacity};

    &:not(:last-child) {
        margin-right: -${tokens.spacing12};
    }
`

const Opacity = () => (
    <>
        <OpacityExample opacity={tokens.opacity80} />
        <OpacityExample opacity={tokens.opacity60} />
        <OpacityExample opacity={tokens.opacity40} />
        <OpacityExample opacity={tokens.opacity20} />
        <OpacityExample opacity={tokens.opacity10} />
    </>
)

const RadiusExample = styled.div<{radius: string}>`
    width: 40px;
    height: 40px;
    border: 1px solid ${tokens.colorBorderInteractiveLoud};
    border-radius: ${({radius}) => radius};

    &:not(:last-child) {
        margin-right: ${tokens.spacing24};
    }
`

const Radius = () => (
    <>
        <RadiusExample radius={tokens.arc8} />
        <RadiusExample radius={tokens.arc12} />
        <RadiusExample radius={tokens.arc99999} />
    </>
)

const SpacingWrapper = styled.div`
    display: flex;
    overflow: hidden;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    background-color: #e5e5f7;
    /* stylelint-disable number-max-precision */
    background-image: repeating-linear-gradient(
        45deg,
        ${tokens.purple700} 0,
        ${tokens.purple700} 0.6000000000000001px,
        #e5e5f7 0,
        #e5e5f7 50%
    );
    background-size: 5px 5px;
    border-radius: ${tokens.spacing4};
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    opacity: 0.8;
`

const SpacingExample = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${tokens.colorContentStatic};

    &:not(:last-child) {
        margin-right: ${tokens.spacing12};
    }
`

const Spacing = () => (
    <SpacingWrapper>
        <SpacingExample />
        <SpacingExample />
        <SpacingExample />
    </SpacingWrapper>
)

const Layering = () => (
    <svg
        style={{fill: tokens.colorContentStatic}}
        height="40px"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 122.88 111.96"
        xmlSpace="preserve"
    >
        <g>
            <path d="M61.15,0L0,26.52l61.41,24.96l61.47-24.88L61.15,0L61.15,0z M122.88,57.12L95.46,45.31L62.73,58.56 c-0.88,0.36-1.83,0.33-2.65,0L27.27,45.22L0,57.05L61.41,82L122.88,57.12L122.88,57.12z M96.14,75.56L62.73,89.08 c-0.88,0.36-1.83,0.33-2.65,0L26.59,75.47L0,87.01l61.41,24.96l61.47-24.88L96.14,75.56L96.14,75.56z"></path>
        </g>
    </svg>
)

const spin = keyframes`
     0% {
        background-color: ${tokens.colorContentStatic};
         border-radius: ${tokens.arc4};
        opacity: 1;
     }

     100% {
        width: 40px;
        background-color: ${tokens.colorContentStaticQuiet};
        border-radius: ${tokens.arc20};
        opacity: 0.5;
     }
 `

const Motion = styled.div`
    width: 40px;
    height: 40px;
    animation: ${spin} 2s infinite alternate;
`

const useTokens = () => {
    const data = useStaticQuery<DesignTokensQuery>(graphql`
        query DesignTokens {
            tokens: allSitePage(
                filter: {context: {frontmatter: {section: {eq: "Design Tokens"}}}}
            ) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                            intro
                        }
                    }
                }
            }
        }
    `)

    const tokens = data.tokens.nodes
        .map((token) => {
            const meta = token.context.frontmatter
            return {
                path: token.path,
                title: meta.title,
                intro: meta.intro
            }
        })
        .filter((token) => token.path.match('.guidelines'))

    return tokens.sort(sort((c) => c.title.toLowerCase()))
}

const getExampleComponentByTitle = (title: string) => {
    switch (title) {
        case 'Color':
            return Color
        case 'Border':
            return Border
        case 'Shadow':
            return Shadow
        case 'Opacity':
            return Opacity
        case 'Typography':
            return Typography
        case 'Radius':
            return Radius
        case 'Spacing':
            return Spacing
        case 'Layering':
            return Layering
        case 'Motion':
            return Motion
        default:
            return 'div'
    }
}

const List = ({tokens}: {tokens: Token[]}) => {
    return (
        <Grid>
            {tokens.map(({path, title, intro}) => {
                const Example = getExampleComponentByTitle(title)
                return (
                    <Card
                        key={path}
                        href={path}
                        title={title}
                        description={intro}
                        illustration={<Illustration>{<Example />}</Illustration>}
                    />
                )
            })}
        </Grid>
    )
}

export const TokensList = () => {
    const tokens = useTokens()

    return <List tokens={tokens} />
}
