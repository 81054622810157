import { Props } from 'docs';
import { Inline, Input, BaseError, BaseInput, FormikInput } from '@pleo-io/telescope';
import { Search } from '@pleo-io/telescope-icons';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import * as React from 'react';
export default {
  Props,
  Inline,
  Input,
  BaseError,
  BaseInput,
  FormikInput,
  Search,
  Formik,
  Form,
  yup,
  React
};