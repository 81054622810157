import {Link as GatsbyLink} from 'gatsby'
import Slugger from 'github-slugger'
import React, {FC, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'

import {tokens, Text, focusRing} from '@pleo-io/telescope'

import {paddingContentLarge} from '@/tokens'

function slugHeadings({
    headings,
    minDepth,
    maxDepth
}: {
    headings: Array<{depth: number; value: string; label?: string}>
    minDepth?: number
    maxDepth?: number
}) {
    const slugger = new Slugger()

    return headings
        .filter((heading) => !['Overview'].includes(heading.label || heading.value))
        .filter((heading) => {
            return heading.depth >= (minDepth ?? 2) && heading.depth <= (maxDepth ?? Infinity)
        })
        .map(({value, label, depth}) => ({
            label,
            value,
            slug: slugger.slug(value),
            depth
        }))
}

function useSlugHeadings({
    headings,
    minDepth,
    maxDepth
}: {
    headings: Array<{depth: number; value: string}>
    minDepth?: number
    maxDepth?: number
}) {
    const ref = useRef<Array<{value: string; slug: string; label?: string; depth: number}>>()

    useEffect(() => {
        ref.current = slugHeadings({headings, minDepth, maxDepth})
    })

    return ref.current ?? slugHeadings({headings, minDepth, maxDepth})
}

const isBrowser = () => typeof window !== 'undefined'

export const ToC: FC<{
    headings?: Array<{depth: number; value: string}>
    minDepth?: number
    maxDepth?: number
}> = ({headings = [], minDepth = 2, maxDepth}) => {
    const [activeSlug, setActiveSlug] = useState(
        isBrowser() ? window.location.hash?.replace('#', '') : ''
    )
    const sections = useSlugHeadings({headings, minDepth, maxDepth})

    const handleClick = (e: React.MouseEvent<HTMLElement>, slug: string) => {
        e.preventDefault()
        setActiveSlug(slug)
        // @ts-ignore-next-line
        window.history.pushState(null, null, `#${slug}`)
    }

    useEffect(() => {
        const element = document.getElementById(activeSlug)
        // @ts-expect-error Typescript 5.0.2 does not have behavior 'instant'
        element?.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'instant'})
    }, [activeSlug])

    if (sections.length === 0) {
        return null
    }

    return (
        <Wrapper>
            <TocTitle>On this page</TocTitle>
            {sections.map(({slug, value, label, depth}, index) => {
                return (
                    <TocItem key={slug} $depth={depth} $minDepth={minDepth}>
                        <TocLink
                            $active={activeSlug === slug}
                            onClick={(e) => handleClick(e, slug)}
                            to={`#${slug}`}
                        >
                            {label ?? value}
                        </TocLink>
                    </TocItem>
                )
            })}
        </Wrapper>
    )
}

const Wrapper = styled.ul`
    position: sticky;
    top: 0;
    max-height: 100vh;
    padding-top: ${tokens.spacing40};
    padding-bottom: ${tokens.spacing40};
    padding-left: ${paddingContentLarge};
    padding-right: ${paddingContentLarge};
    overflow-y: auto;
`

const TocTitle = styled(Text).attrs({
    variant: 'large-accent',
    as: 'div',
    weight: 'semibold'
})`
    padding-bottom: ${tokens.spacing18};
`

const TocLink = styled(GatsbyLink)<{$active: boolean}>`
    ${focusRing('regular')};
    transform: translateX(0);
    cursor: pointer;

    &,
    &:visited {
        color: ${({$active}) =>
            $active ? tokens.colorContentInteractive : tokens.colorContentInteractiveQuiet};
    }

    font-weight: ${({$active}) => ($active ? tokens.fontWeightSemibold : tokens.fontWeightRegular)};
`

const TocItem = styled.li<{$depth: number; $minDepth: number}>`
    padding-top: ${tokens.spacing4};
    padding-bottom: ${tokens.spacing4};
    padding-left: ${({$depth, $minDepth}) =>
        $depth > 2 ? ($depth - $minDepth) * parseInt(tokens.spacing16, 10) : 0}px;
    font-size: ${tokens.fontMedium};
`
