export const blue000 = '#fafcfe'
export const blue100 = '#f5f8fd'
export const blue200 = '#e8f1fc'
export const blue300 = '#d2e3f9'
export const blue400 = '#bbd4f7'
export const blue500 = '#9cc1f3'
export const blue600 = '#6da4ed'
export const blue700 = '#4588e3'
export const blue800 = '#286ecc'
export const blue900 = '#27589b'
export const green000 = '#fbfefc'
export const green100 = '#f4f9f6'
export const green200 = '#ecf9f0'
export const green300 = '#d9f2e1'
export const green400 = '#c6ecd2'
export const green500 = '#ace3bd'
export const green600 = '#85d69e'
export const green700 = '#6abe84'
export const green800 = '#2c8354'
export const green900 = '#255937'
export const pink000 = '#fffafb'
export const pink100 = '#fff5f6'
export const pink200 = '#ffebed'
export const pink300 = '#ffdee2'
export const pink400 = '#ffc8d0'
export const pink500 = '#f39ca8'
export const pink600 = '#dc818e'
export const pink700 = '#cb727f'
export const pink800 = '#b2535f'
export const pink900 = '#7b3840'
export const purple000 = '#fbfbfe'
export const purple100 = '#f8f7fd'
export const purple200 = '#edebf9'
export const purple300 = '#dcd7f4'
export const purple400 = '#b3a9e7'
export const purple500 = '#a69ae3'
export const purple600 = '#8474d8'
export const purple700 = '#7363c5'
export const purple800 = '#5442b3'
export const purple900 = '#43387a'
export const red000 = '#fffafa'
export const red100 = '#fff5f5'
export const red200 = '#fee6e6'
export const red300 = '#fecdcd'
export const red400 = '#fdb5b5'
export const red500 = '#fc9090'
export const red600 = '#fb6060'
export const red700 = '#e91c1c'
export const red800 = '#d61f1f'
export const red900 = '#a51d1d'
export const shade000 = '#ffffff'
export const shade100 = '#fafafa'
export const shade200 = '#f5f5f5'
export const shade300 = '#ececec'
export const shade400 = '#cccccc'
export const shade500 = '#b3b3b3'
export const shade600 = '#737373'
export const shade700 = '#333333'
export const shade800 = '#222222'
export const shade900 = '#000000'
export const yellow000 = '#fffefa'
export const yellow100 = '#fffdf5'
export const yellow200 = '#fcf9e4'
export const yellow300 = '#fef6cd'
export const yellow400 = '#fbefaf'
export const yellow500 = '#fcea88'
export const yellow600 = '#fbe360'
export const yellow700 = '#dfc538'
export const yellow800 = '#dbbd1a'
export const yellow900 = '#b39a0f'
