import { ComponentExample, DosAndDonts, Features, Feature, RelatedComponents } from 'docs';
import { Banner, Inline, Link } from '@pleo-io/telescope';
import { Lightbulb } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  ComponentExample,
  DosAndDonts,
  Features,
  Feature,
  RelatedComponents,
  Banner,
  Inline,
  Link,
  Lightbulb,
  React
};