import { ComponentExample, DosAndDonts, Features, Feature, RelatedComponents } from 'docs';
import { Count, Checkbox, Inline } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  ComponentExample,
  DosAndDonts,
  Features,
  Feature,
  RelatedComponents,
  Count,
  Checkbox,
  Inline,
  React
};