import { Props } from 'docs';
import { List, ListItem, ListIcon, tokens } from '@pleo-io/telescope';
import { Check, Close } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Props,
  List,
  ListItem,
  ListIcon,
  tokens,
  Check,
  Close,
  React
};