import { Features, Feature, ComponentExample, RelatedComponents } from 'docs';
import { Stack, Text, Input, BaseError, BaseInput, CurrencyInput, FormikCurrencyInput } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  Stack,
  Text,
  Input,
  BaseError,
  BaseInput,
  CurrencyInput,
  FormikCurrencyInput,
  React
};