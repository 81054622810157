import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

const FoundationsIllustrationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 230px;
    margin-bottom: ${tokens.spacing24};
    background-color: ${tokens.shade100};
    border-radius: ${tokens.arc12};
`

export const FoundationsIllustration = ({children}: any) => {
    return <FoundationsIllustrationWrapper>{children}</FoundationsIllustrationWrapper>
}
