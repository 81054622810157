import { ComponentExample, DosAndDonts, Features, Feature, Props, RelatedComponents, Summary, Details } from 'docs';
import { Checkbox, Button, VisuallyHidden } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  ComponentExample,
  DosAndDonts,
  Features,
  Feature,
  Props,
  RelatedComponents,
  Summary,
  Details,
  Checkbox,
  Button,
  VisuallyHidden,
  React
};