import { ComponentExample, Features, Feature, RelatedComponents } from 'docs';
import { FilterResults } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  RelatedComponents,
  FilterResults,
  React
};