import React from 'react'
import styled from 'styled-components'

import {Box, Inline, Text, tokens} from '@pleo-io/telescope'
import {Check, Close} from '@pleo-io/telescope-icons'

const Content = styled.div`
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-top: ${tokens.spacing18};
`

const Icon = styled.div`
    position: absolute;
    top: ${tokens.spacing12};
    left: ${tokens.spacing12};
    display: flex;
    gap: ${tokens.spacing6};
    align-items: center;
`

const Example = styled.div<{
    $color: string
    $backgroundColor: string
    $borderColor: string
    $image: boolean
}>`
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: ${({$image}) => ($image ? 0 : `${tokens.spacing60} ${tokens.spacing36}`)};
    background-color: ${tokens.colorBackgroundStaticLoud};
    border-bottom: ${tokens.sizeBorderThick} solid ${({$color}) => $color};

    ${Icon} svg {
        padding: ${tokens.spacing4};
        color: ${({$color}) => $color};
        background-color: ${({$backgroundColor}) => $backgroundColor};
        border: ${tokens.sizeBorderDefault} solid ${({$borderColor}) => $borderColor};
        border-radius: ${tokens.arc99999};
    }
`

const Heading = styled(Text).attrs({weight: 'bold'})``

const ExampleContent = styled.div<{$text: boolean}>`
    transform: ${({$text}) => ($text ? 'none' : 'scale(0.85)')};
    pointer-events: none;
`

const Wrapper = styled(Box).attrs({marginY: 24})`
    & + & {
        margin-top: ${tokens.spacing36};
    }
`

export const DosAndDonts = ({
    doText,
    dontText,
    doExample,
    dontExample,
    image = false,
    text = false
}: any) => (
    <Wrapper>
        {doExample && dontExample && (
            <Inline alignItems="stretch" space={24}>
                <Example
                    $color={tokens.colorContentPositive}
                    $backgroundColor={tokens.colorBackgroundPositiveQuiet}
                    $borderColor={tokens.colorBorderPositive}
                    $image={image}
                >
                    <Icon>
                        <Check />
                    </Icon>
                    {image ? doExample : <ExampleContent $text={text}>{doExample}</ExampleContent>}
                </Example>
                <Example
                    $color={tokens.colorContentNegative}
                    $backgroundColor={tokens.colorBackgroundNegativeQuiet}
                    $borderColor={tokens.colorBorderNegative}
                    $image={image}
                >
                    <Icon>
                        <Close />
                    </Icon>
                    {image ? (
                        dontExample
                    ) : (
                        <ExampleContent $text={text}>{dontExample}</ExampleContent>
                    )}
                </Example>
            </Inline>
        )}
        {!!doText && !!dontText && (
            <Inline alignItems="stretch" space={24}>
                <Content>
                    <Heading color="colorContentPositive">Do</Heading>
                    <Text>{doText}</Text>
                </Content>
                <Content>
                    <Heading color="colorContentNegative">Don't</Heading>
                    <Text>{dontText}</Text>
                </Content>
            </Inline>
        )}
    </Wrapper>
)
