import accessibility from '@/assets/foundations/accessibility.png';
import lightbulb from '@/assets/lightbulb.svg';
import { Linkbox, FoundationsIllustration } from 'docs';
import * as React from 'react';
export default {
  accessibility,
  lightbulb,
  Linkbox,
  FoundationsIllustration,
  React
};