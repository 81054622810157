import { Features, Feature, ComponentExample } from 'docs';
import { HelpPopover, Stack, Inline, Text, Link } from '@pleo-io/telescope';
import { RelatedComponents } from 'docs';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  HelpPopover,
  Stack,
  Inline,
  Text,
  Link,
  RelatedComponents,
  React
};