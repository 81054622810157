import { Props } from 'docs';
import { PromotionalBanner } from '@pleo-io/telescope';
import dollarsign from '@/assets/dollarsign.svg';
import { Table, TableHead, TableHeadRow, TableHeadData, TableBody, TableBodyData, TableBodyRow } from '@/components/docs/table';
import * as React from 'react';
export default {
  Props,
  PromotionalBanner,
  dollarsign,
  Table,
  TableHead,
  TableHeadRow,
  TableHeadData,
  TableBody,
  TableBodyData,
  TableBodyRow,
  React
};