import { ComponentExample, DosAndDonts, Features, Feature, Props, RelatedComponents } from 'docs';
import { Skeleton, Checkbox, Switch, Text, Stack, Inline, Avatar } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  ComponentExample,
  DosAndDonts,
  Features,
  Feature,
  Props,
  RelatedComponents,
  Skeleton,
  Checkbox,
  Switch,
  Text,
  Stack,
  Inline,
  Avatar,
  React
};