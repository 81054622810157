import styled from 'styled-components'

import {Text} from '@pleo-io/telescope'
import tokens from '@pleo-io/telescope-tokens'

export const Details = styled.details`
    margin: ${tokens.spacing20} 0;

    > *:not(summary) {
        margin-left: ${tokens.spacing16};
    }
`

export const Summary = styled(Text).attrs({variant: 'medium-default', as: 'summary'})`
    cursor: pointer;
`
