import Fuse from 'fuse.js'
import {useState} from 'react'

const fuzzySearch = <T,>(data: T[], term: string, fuse: any) => {
    const result = fuse.search(`${term}`) as {item: T}[]
    return term ? result.map((r) => r.item) : data
}

const useFuse = <T,>(
    data: T[],
    options: any = {}
): {
    result: T[]
    search: (term: string) => void
    term: string
} => {
    const [term, setTerm] = useState('')
    const fuseOptions = {threshold: 0.2, ...options}
    const fuse = new Fuse(data, fuseOptions)
    const result = fuzzySearch(data, term, fuse)
    return {result, search: setTerm, term}
}

export default useFuse
