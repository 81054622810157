import { Features, Feature, ComponentExample, RelatedComponents } from 'docs';
import { FormControl, Input, Select, Text, Stack, Checkbox, Link, RadioButton } from '@pleo-io/telescope';
import { CategoryEntertainment, SpeechBubble, Wallet, MinusCircle } from '@pleo-io/telescope-icons';
import { Labels, HintText, ErrorMessages, RequiredAndOptionalFields } from '@/components/docs/patterns/forms';
import dollarsign from '@/assets/dollarsign.svg';
import jeppe from '@/assets/jeppe.png';
import niccolo from '@/assets/niccolo.png';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  FormControl,
  Input,
  Select,
  Text,
  Stack,
  Checkbox,
  Link,
  RadioButton,
  CategoryEntertainment,
  SpeechBubble,
  Wallet,
  MinusCircle,
  Labels,
  HintText,
  ErrorMessages,
  RequiredAndOptionalFields,
  dollarsign,
  jeppe,
  niccolo,
  React
};