import * as React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'
import motion from '@pleo-io/telescope-tokens/dist/motion.json'

import {InfoPopover} from '@/components/docs/props'
import {
    Table,
    TableBody,
    TableBodyData,
    TableBodyRow,
    TableHead,
    TableHeadData,
    TableHeadRow,
    TokenName
} from '@/components/docs/table'
import {backgroundColorExample} from '@/tokens'

interface MotionGridProps {
    type: 'Enter' | 'Exit' | 'Within'
}

export const MotionTokenGrid = ({type}: MotionGridProps) => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData></TableHeadData>
                    <TableHeadData>
                        Small element
                        <InfoPopover>E.g. a tooltip, button or popover</InfoPopover>
                    </TableHeadData>
                    <TableHeadData>
                        Large element
                        <InfoPopover>
                            E.g. a drawer sliding in from the side of the page
                        </InfoPopover>
                    </TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                <TableBodyRow>
                    <TableBodyData>
                        <b>Short distance</b>
                        <InfoPopover>E.g. a switch toggling from on to off</InfoPopover>
                    </TableBodyData>
                    <TableBodyData>
                        <TokenName name={`motion${type}SmallShort`} />
                    </TableBodyData>
                    <TableBodyData>
                        <TokenName name={`motion${type}LargeShort`} />
                    </TableBodyData>
                </TableBodyRow>
                <TableBodyRow>
                    <TableBodyData>
                        <b>Long distance</b>
                        <InfoPopover>
                            E.g. a drawer sliding in from the side of the page
                        </InfoPopover>
                    </TableBodyData>
                    <TableBodyData>
                        <TokenName name={`motion${type}SmallLong`} />
                    </TableBodyData>
                    <TableBodyData>
                        <TokenName name={`motion${type}LargeLong`} />
                    </TableBodyData>
                </TableBodyRow>
            </TableBody>
        </Table>
    )
}

const TransitionElement = styled.div<{transition: string | number}>`
    width: 20px;
    height: 20px;
    background-color: ${backgroundColorExample};
    border-radius: ${tokens.arc99999};
    transition: ${({transition}) => transition};
`

const TransitionWrapper = styled.div`
    &:hover ${TransitionElement} {
        transform: translateX(50px);
    }
`

const Example = ({transition}: {transition: string | number}) => (
    <TransitionWrapper>
        <TransitionElement transition={transition} />
    </TransitionWrapper>
)

export const Motion = () => {
    const tokens = motion.props.filter(({type}) => ['within', 'enter', 'exit'].includes(type))

    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Value</TableHeadData>
                    <TableHeadData>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {tokens.map(({name, value, description}) => (
                    <TableBodyRow key={name}>
                        <TableBodyData css={{display: 'flex'}}>
                            <TokenName name={name} />
                            <InfoPopover>{description}</InfoPopover>
                        </TableBodyData>
                        <TableBodyData>{value}</TableBodyData>
                        <TableBodyData>
                            <Example transition={value} />
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}
