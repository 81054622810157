import { Example } from 'docs';
import { focusRing, FilterResults, Button, DatePicker, Checkbox } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Example,
  focusRing,
  FilterResults,
  Button,
  DatePicker,
  Checkbox,
  React
};