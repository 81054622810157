import { Props } from 'docs';
import { ActionMenu, Avatar, AvatarGroup, Box, Button, Drawer, Inline, ProgressBar, Stack, Text, Tabs } from '@pleo-io/telescope';
import jeppe from '@/assets/jeppe.png';
import niccolo from '@/assets/niccolo.png';
import * as React from 'react';
export default {
  Props,
  ActionMenu,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Drawer,
  Inline,
  ProgressBar,
  Stack,
  Text,
  Tabs,
  jeppe,
  niccolo,
  React
};