import {createGlobalStyle} from 'styled-components'

export const ReachUI = createGlobalStyle`
  /*
    @reach/ui ignore warning for style not imported, we provide our own style
    see: https://reacttraining.com/reach-ui/styling/
   */

  :root {
    /* stylelint-disable-next-line custom-property-pattern */
    --reach-dialog: 1;
  }
`
