import { Box, Button, Inline, Modal, ModalActions, ModalClose, ModalContent, ModalFooter, ModalIllustration, ModalSubtitle, ModalTitle, NakedButton, Popover, Text, Tooltip } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Box,
  Button,
  Inline,
  Modal,
  ModalActions,
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalIllustration,
  ModalSubtitle,
  ModalTitle,
  NakedButton,
  Popover,
  Text,
  Tooltip,
  React
};