import Lottie from 'lottie-react'
import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'
import CatBox from '@pleo-io/telescope-animated-illustrations/lottie/cat-box.json'

import {useIllustrationsImages} from './use-overview-images'
import {useIllustrations} from './use-overview-pages'

import {Card} from '../card'
import {Grid} from '../list-grid'

const AnimationWrapper = styled.div`
    padding: ${tokens.spacing32};

    rect {
        fill: transparent;
    }
`

const IllustrationImage = styled.img`
    max-width: 100%;
    margin: 0 auto;
`

export const OverviewList = () => {
    const illustrations = useIllustrations()
    const images = useIllustrationsImages()

    return (
        <Grid>
            {illustrations.map(({path, title, intro}) => {
                const image = images[title.toLowerCase().replace(/ /g, '-')]
                return (
                    <Card
                        key={path}
                        href={path}
                        title={title}
                        illustration={
                            <Card.Illustration>
                                {title === 'Animated' ? (
                                    <AnimationWrapper>
                                        <Lottie animationData={CatBox} loop />
                                    </AnimationWrapper>
                                ) : (
                                    <IllustrationImage alt={`${title} preview`} src={image} />
                                )}
                            </Card.Illustration>
                        }
                        description={intro}
                    />
                )
            })}
        </Grid>
    )
}
