import { ComponentExample, Features, Feature, RelatedComponents } from 'docs';
import { List, ListItem, ListIcon, tokens } from '@pleo-io/telescope';
import { Check, Close } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  RelatedComponents,
  List,
  ListItem,
  ListIcon,
  tokens,
  Check,
  Close,
  React
};