import { Features, Feature, ComponentExample, RelatedComponents, DosAndDonts } from 'docs';
import { ActionMenu, Button, IconButton, Inline } from '@pleo-io/telescope';
import { Options, Share, Heart, Trash, Copy, Download, Gallery, CalendarClock, Pen } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  DosAndDonts,
  ActionMenu,
  Button,
  IconButton,
  Inline,
  Options,
  Share,
  Heart,
  Trash,
  Copy,
  Download,
  Gallery,
  CalendarClock,
  Pen,
  React
};