import { ComponentExample, DosAndDonts, Features, Feature, Props, RelatedComponents, RawPropsTable } from 'docs';
import { Button, ModalSplit, useModalSplitSteps } from '@pleo-io/telescope';
import pocket from '@/assets/modal-split/pocket.svg';
import * as React from 'react';
export default {
  ComponentExample,
  DosAndDonts,
  Features,
  Feature,
  Props,
  RelatedComponents,
  RawPropsTable,
  Button,
  ModalSplit,
  useModalSplitSteps,
  pocket,
  React
};