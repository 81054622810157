import { Features, Feature, ComponentExample, DosAndDonts, RelatedComponents } from 'docs';
import { Switch, Inline } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  DosAndDonts,
  RelatedComponents,
  Switch,
  Inline,
  React
};