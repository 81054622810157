import React from 'react'
import styled from 'styled-components'

import radius from '@pleo-io/telescope-tokens/dist/radius.json'

import {
    Table,
    TableHead,
    TableHeadRow,
    TableHeadData,
    TableBody,
    TableBodyRow,
    TableBodyData,
    TokenName
} from '@/components/docs/table'
import {backgroundColorExample} from '@/tokens'

const Example = styled.div<{$radius: string; $circle: boolean}>`
    height: 20px;
    width: ${({$circle}) => $circle && '20px'};
    background-color: ${backgroundColorExample};
    border-radius: ${({$radius}) => $radius};
`

export const Radius = () => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Value</TableHeadData>
                    <TableHeadData>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {radius.props.map(({name, value}) => (
                    <TableBodyRow key={name}>
                        <TableBodyData>
                            <TokenName name={name} />
                        </TableBodyData>
                        <TableBodyData>{value}</TableBodyData>
                        <TableBodyData>
                            <Example $radius={value} $circle={name === 'circle'} />
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default Radius
