import { Features, Feature, ComponentExample, RelatedComponents } from 'docs';
import { Text, Inline, Tabs, Link } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  Text,
  Inline,
  Tabs,
  Link,
  React
};