import contributing from '@/assets/contributing.svg';
import { FancyList, Intro } from 'docs';
import { List, ListItem, Link } from '@pleo-io/telescope';
import { SpeechBubble } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  contributing,
  FancyList,
  Intro,
  List,
  ListItem,
  Link,
  SpeechBubble,
  React
};