import {AnimatePresence, motion} from 'framer-motion'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'

import {Button, Stack} from '@pleo-io/telescope'
import tokens from '@pleo-io/telescope-tokens'

const ExpandingButton = styled(motion.button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    color: ${tokens.colorContentStatic};
    font-size: ${tokens.fontLarge};
    background-color: ${tokens.colorBackgroundPresentationalPink};
    border: none;
    border-radius: ${tokens.arc12};
    cursor: pointer;
`

export function BeginningAndEndingOnScreen() {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <ExpandingButton
            animate={isExpanded ? 'expanded' : 'contracted'}
            variants={{
                expanded: {
                    scale: 1.2,
                    transition: {
                        scale: tokens.motionWithinSmallShort
                    }
                },
                contracted: {
                    scale: 0.7,
                    transition: {
                        scale: tokens.motionWithinSmallShort
                    }
                }
            }}
            onClick={() => setIsExpanded(!isExpanded)}
        >
            Click me
        </ExpandingButton>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 240px;
    min-height: 160px;
`

const AnimatedDiv = styled(motion.div)`
    width: 240px;
    height: 50px;
    margin: ${tokens.spacing12} auto 0 auto;
    color: ${tokens.colorContentStatic};
    font-size: ${tokens.fontLarge};
    background-color: ${tokens.colorBackgroundPresentationalPink};
    border-radius: ${tokens.arc12};
`

export function EnterAndExit({defaultIsVisible = false}: {defaultIsVisible?: boolean}) {
    const [isVisible, setIsVisible] = useState(defaultIsVisible)

    return (
        <Wrapper>
            <Button
                variant="primary"
                onClick={() => {
                    setIsVisible(!isVisible)
                }}
            >
                Toggle visibility
            </Button>
            <AnimatePresence>
                {isVisible && (
                    <AnimatedDiv
                        initial={{
                            opacity: 0,
                            y: 15
                        }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                                opacity: tokens.motionEnterSmallShort,
                                y: tokens.motionEnterSmallShort
                            }
                        }}
                        exit={{
                            opacity: 0,
                            y: 6,
                            transition: {
                                opacity: tokens.motionExitSmallShort,
                                y: tokens.motionExitSmallShort
                            }
                        }}
                    ></AnimatedDiv>
                )}
            </AnimatePresence>
        </Wrapper>
    )
}

const BIG_THING_WIDTH = 240
const SMALL_THING_WIDTH = 30

const BigThing = styled(motion.div)`
    background-color: ${tokens.colorBackgroundPresentationalPink};
    border-radius: ${tokens.arc12};
    height: 240px;
    width: ${BIG_THING_WIDTH}px;
`

const SmallThing = styled(motion.div)`
    background-color: ${tokens.colorBackgroundPresentationalPink};
    border-radius: ${tokens.arc12};
    height: 30px;
    width: ${SMALL_THING_WIDTH}px;
`

const sizeVariants = {
    left: ({size}: {size: 'small' | 'big'}) => ({
        x: 0,
        transition: {
            x:
                size === 'big'
                    ? {duration: tokens.motionDurationSlow}
                    : {duration: tokens.motionDurationModerate}
        }
    }),
    right: ({size, maxX}: {size: 'small' | 'big'; maxX?: number}) => ({
        x: maxX ?? 300,
        transition: {
            x:
                size === 'big'
                    ? {duration: tokens.motionDurationSlow}
                    : {duration: tokens.motionDurationModerate}
        }
    })
}

export const SizeExample = () => {
    const [position, setPosition] = useState<'left' | 'right'>('left')
    const container = useRef<HTMLDivElement>(null)
    const maxX = container.current?.offsetWidth
        ? container.current?.offsetWidth - BIG_THING_WIDTH
        : 300

    return (
        <Stack space={24} ref={container}>
            <Button
                variant="primary"
                onClick={() => setPosition((prev) => (prev === 'left' ? 'right' : 'left'))}
            >
                Change position
            </Button>
            <Stack space={40}>
                <BigThing
                    animate={position === 'left' ? 'left' : 'right'}
                    variants={sizeVariants}
                    custom={{size: 'big', maxX}}
                />
                <SmallThing
                    animate={position === 'left' ? 'left' : 'right'}
                    variants={sizeVariants}
                    custom={{size: 'small', maxX}}
                />
            </Stack>
        </Stack>
    )
}

const distanceVariants = {
    left: ({distance}: {distance: 'short' | 'long'}) => ({
        x: 0,
        transition: {
            x:
                distance === 'long'
                    ? {duration: tokens.motionDurationSlow}
                    : {duration: tokens.motionDurationModerate}
        }
    }),
    right: ({distance, maxX}: {distance: 'short' | 'long'; maxX?: number}) => ({
        x: distance === 'short' ? 100 : maxX,
        transition: {
            x:
                distance === 'long'
                    ? {duration: tokens.motionDurationSlow}
                    : {duration: tokens.motionDurationModerate}
        }
    })
}

export const DistanceExample = () => {
    const [position, setPosition] = useState<'left' | 'right'>('left')
    const container = useRef<HTMLDivElement>(null)
    const maxX = container.current?.offsetWidth
        ? container.current?.offsetWidth - SMALL_THING_WIDTH
        : 400

    return (
        <Stack space={24} ref={container}>
            <Button
                variant="primary"
                onClick={() => setPosition((prev) => (prev === 'left' ? 'right' : 'left'))}
            >
                Change position
            </Button>
            <Stack space={24}>
                <SmallThing
                    animate={position === 'left' ? 'left' : 'right'}
                    variants={distanceVariants}
                    custom={{distance: 'short', maxX}}
                />
                <SmallThing
                    animate={position === 'left' ? 'left' : 'right'}
                    variants={distanceVariants}
                    custom={{distance: 'long', maxX}}
                />
            </Stack>
        </Stack>
    )
}

const StaggeredWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 240px;
    min-height: 280px;
`

const staggeredVariants = {
    hidden: {opacity: 0, y: 6},
    visible: ({itemIndex}: {itemIndex: number}) => ({
        opacity: 1,
        y: 0,
        transition: {
            opacity: {
                duration: tokens.motionDurationModerate,
                delay: itemIndex * tokens.motionDurationStep
            },
            y: {
                duration: tokens.motionDurationModerate,
                delay: itemIndex * tokens.motionDurationStep
            }
        }
    }),
    leave: ({itemIndex, length}: {itemIndex: number; length: number}) => ({
        opacity: 0,
        y: 6,
        transition: {
            opacity: {
                duration: tokens.motionDurationFast,
                delay: Math.abs(itemIndex - length) * tokens.motionDurationStep
            },
            y: {
                duration: tokens.motionDurationFast,
                delay: Math.abs(itemIndex - length) * tokens.motionDurationStep
            }
        }
    })
}

export function StaggeredAnimations() {
    const [isVisible, setIsVisible] = useState(false)
    const items = [
        {
            id: 1,
            text: 'Item one'
        },
        {
            id: 2,
            text: 'Item two'
        },
        {
            id: 3,
            text: 'Item three'
        }
    ]

    return (
        <StaggeredWrapper>
            <Button
                variant="primary"
                onClick={() => {
                    setIsVisible(!isVisible)
                }}
            >
                Toggle visibility
            </Button>
            <AnimatePresence>
                {isVisible ? (
                    <>
                        {items.map((item, index) => {
                            return (
                                <AnimatedDiv
                                    key={item.id}
                                    variants={staggeredVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="leave"
                                    custom={{itemIndex: index, length: items.length}}
                                ></AnimatedDiv>
                            )
                        })}
                    </>
                ) : null}
            </AnimatePresence>
        </StaggeredWrapper>
    )
}
