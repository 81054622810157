import React from 'react'

import {Fonts} from './fonts'
import {ReachUI} from './reach'
import {Reset} from './reset'

export const GlobalStyle = () => (
    <>
        <Reset />
        <ReachUI />
        <Fonts />
    </>
)

export * from './fonts'
export * from './reach'
export * from './reset'
