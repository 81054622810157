import color from '@/assets/foundations/color.svg';
import lightbulb from '@/assets/lightbulb.svg';
import { Linkbox, FoundationsIllustration } from 'docs';
import * as React from 'react';
export default {
  color,
  lightbulb,
  Linkbox,
  FoundationsIllustration,
  React
};