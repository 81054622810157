import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

const Iframe = styled.iframe`
    border: 1px solid ${tokens.colorBorderStatic};
    border-radius: ${tokens.arc8};
`

export const FigmaEmbed = ({height = 450, src}: {height: number; src: string}) => (
    <Iframe title="title" width="100%" height={height} src={src} allowFullScreen></Iframe>
)
