import React from 'react'

import tokens from '@pleo-io/telescope-tokens'

import {StaticImage} from './static-image'

const Figcaption = ({caption}: {caption: string}) => (
    <figcaption
        css={{
            color: tokens.colorContentStaticQuiet,
            fontSize: tokens.fontMedium,
            marginTop: tokens.spacing8
        }}
    >
        {caption}
    </figcaption>
)

export const Figure = ({src, caption}: {src: string; caption: string}) => (
    <figure css={{marginBlock: tokens.spacing36}}>
        <StaticImage src={src} alt={caption} />
        <Figcaption caption={caption} />
    </figure>
)
