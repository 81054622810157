import styled from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'

export const Intro = styled(Text)`
    max-width: 60ch;
    margin-bottom: ${tokens.spacing36};
    font-size: ${tokens.fontXLarge};
    line-height: ${tokens.lineHeight3};

    a {
        font-size: inherit;
    }
`
