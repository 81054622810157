import { ComponentExample, Features, Feature, RelatedComponents, DosAndDonts } from 'docs';
import { Tag } from '@pleo-io/telescope';
import { FlashCircle, CategoryMarketing, Warning, HighFive, Sandwich, Reimburse, Wand } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  RelatedComponents,
  DosAndDonts,
  Tag,
  FlashCircle,
  CategoryMarketing,
  Warning,
  HighFive,
  Sandwich,
  Reimburse,
  Wand,
  React
};