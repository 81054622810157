import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {SearchInput} from './search-input'

import {minWidthTOC} from '../layout/global-styles'

export const OverviewPage = ({children}: {children: React.ReactNode}) => <>{children}</>

export const OverviewPageSearch = styled(SearchInput)`
    max-width: 300px;
    margin-bottom: ${tokens.spacing36};
`

export const OverviewPageCards = styled.ul`
    display: grid;
    grid-gap: ${tokens.spacing24};
    grid-template-columns: 1fr;
    align-items: stretch;
    justify-items: stretch;
    margin: 0;
    margin-bottom: ${tokens.spacing48};
    padding: 0;
    padding-bottom: ${tokens.spacing24};
    list-style: none;

    @media (width >= 450px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${minWidthTOC}) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

OverviewPage.Search = OverviewPageSearch
OverviewPage.Cards = OverviewPageCards
