import React from 'react'

import zIndex from '@pleo-io/telescope-tokens/dist/z-index.json'

import {
    Table,
    TableHead,
    TableHeadRow,
    TableHeadData,
    TableBody,
    TableBodyRow,
    TableBodyData,
    TokenName
} from '@/components/docs/table'

export const ZIndex = () => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Value</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {zIndex.props.map(({name, value}) => (
                    <TableBodyRow key={name}>
                        <TableBodyData>
                            <TokenName name={name} />
                        </TableBodyData>
                        <TableBodyData>{value}</TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default ZIndex
