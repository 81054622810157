import { Props } from 'docs';
import { Modal, Button, Inline, Text } from '@pleo-io/telescope';
import { Download } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Props,
  Modal,
  Button,
  Inline,
  Text,
  Download,
  React
};