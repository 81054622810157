// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-mdx-changelog-renderer-tsx": () => import("./../../../src/components/mdx/changelog-renderer.tsx" /* webpackChunkName: "component---src-components-mdx-changelog-renderer-tsx" */),
  "component---src-components-mdx-mdx-renderer-tsx": () => import("./../../../src/components/mdx/mdx-renderer.tsx" /* webpackChunkName: "component---src-components-mdx-mdx-renderer-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

