import { Features, Feature, ComponentExample, RelatedComponents } from 'docs';
import { Modal, Button, Inline, Link, Text } from '@pleo-io/telescope';
import { Download } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  Modal,
  Button,
  Inline,
  Link,
  Text,
  Download,
  React
};