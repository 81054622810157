import React from 'react'
import styled from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'
import {TextProps} from '@pleo-io/telescope/dist/components/text'

const StyledText = styled(Text)`
    position: relative;

    /* Make sure the link icon doesn't disappear when we try to hover on it. */
    left: -20px;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    padding-left: 20px;
    cursor: pointer;

    &::after {
        position: absolute;
        bottom: 4px;
        left: 0;
        display: none;
        font-size: ${tokens.fontSmall};
        content: '🔗';
    }

    &:hover {
        &::after {
            display: block;
        }
    }
`

export const HeadingLink = ({
    // need to use different name for prop than "as", otherwise
    // the "as" element (e.g. h3)is used directly instead of this custom elemenet
    element,
    ...props
}: TextProps & {
    element: React.ElementType
}) => {
    return (
        <StyledText
            {...props}
            as={element}
            onClick={(event: React.MouseEvent) => {
                const id = event.currentTarget.getAttribute('id')
                if (id) {
                    window.location.hash = id
                }
            }}
        />
    )
}
