import * as border from './border'
import * as color from './color'
import * as colorSchemeLight from './color-scheme-light'
import * as duration from './duration'
import * as fontSize from './font-size'
import * as fontWeight from './font-weight'
import * as lineHeight from './line-height'
import * as motion from './motion'
import * as opacity from './opacity'
import * as radius from './radius'
import * as rgba from './rgba'
import * as spacing from './spacing'
import * as transition from './transition'
import * as zIndex from './z-index'

export const __classNameColorSchemeDark = 'js-telescope-color-scheme-dark'

export {
    color,
    colorSchemeLight,
    duration,
    fontSize,
    fontWeight,
    lineHeight,
    motion,
    opacity,
    radius,
    spacing,
    transition,
    zIndex
}

const tokens = {
    ...border,
    ...color,
    ...duration,
    ...fontSize,
    /**
     * @deprecated Use 'fontSmall' instead
     */
    fontXSmall: fontSize.fontXSmall,
    ...fontWeight,
    ...lineHeight,
    ...motion,
    ...opacity,
    ...radius,
    ...rgba,
    ...spacing,
    ...transition,
    ...zIndex,
    heightInputAndButton: '40px',
    /**
     * @description Default colour for text and icons that are part of interactive elements.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorContentInteractive: 'var(--colorContentInteractive)',

    /**
     * @description Text and icons that are part of interactive elements in a hover state.
     * @coreToken shade900 (color scheme light)
     * @rawValue #000000 (color scheme light)
     */
    colorContentInteractiveHover: 'var(--colorContentInteractiveHover)',

    /**
     * @description Text and icons that are part of interactive elements that should attract less attention.
     * @coreToken shade600 (color scheme light)
     * @rawValue #737373 (color scheme light)
     */
    colorContentInteractiveQuiet: 'var(--colorContentInteractiveQuiet)',

    /**
     * @description Text and icons that are part of links.
     * @coreToken pink800 (color scheme light)
     * @rawValue #b2535f (color scheme light)
     */
    colorContentInteractiveLink: 'var(--colorContentInteractiveLink)',

    /**
     * @coreToken pink900 (color scheme light)
     * @rawValue #7b3840 (color scheme light)
     */
    colorContentInteractiveLinkHover: 'var(--colorContentInteractiveLinkHover)',

    /**
     * @description Text and icons that are part of interactive elements in a disabled state.
     * @coreToken shade500 (color scheme light)
     * @rawValue #b3b3b3 (color scheme light)
     */
    colorContentInteractiveDisabled: 'var(--colorContentInteractiveDisabled)',

    /**
     * @description Text and icons that are part of interactive elements with reversed background and foreground colours.
     * @coreToken shade000 (color scheme light)
     * @rawValue #ffffff (color scheme light)
     */
    colorContentInteractiveInverse: 'var(--colorContentInteractiveInverse)',

    /**
     * @description Text and icons that are part of interactive elements with reversed background and foreground colours in a hover state.
     * @coreToken shade000 (color scheme light)
     * @rawValue #ffffff (color scheme light)
     */
    colorContentInteractiveInverseHover: 'var(--colorContentInteractiveInverseHover)',

    /**
     * @description Text and icons that are part of interactive elements with reversed background and foreground colours and that should attract less attention.
     * @coreToken shade500 (color scheme light)
     * @rawValue #b3b3b3 (color scheme light)
     */
    colorContentInteractiveInverseQuiet: 'var(--colorContentInteractiveInverseQuiet)',

    /**
     * @description Text and icons that are part of interactive elements in a selected state.
     * @coreToken shade000 (color scheme light)
     * @rawValue #ffffff (color scheme light)
     */
    colorContentInteractiveSelected: 'var(--colorContentInteractiveSelected)',

    /**
     * @description Text and icons that are part of interactive elements in a selected and hover state.
     * @coreToken shade000 (color scheme light)
     * @rawValue #ffffff (color scheme light)
     */
    colorContentInteractiveSelectedHover: 'var(--colorContentInteractiveSelectedHover)',

    /**
     * @description Text and icons used as placeholders in form input elements.
     * @coreToken shade600 (color scheme light)
     * @rawValue #737373 (color scheme light)
     */
    colorContentInteractivePlaceholder: 'var(--colorContentInteractivePlaceholder)',

    /**
     * @description Default colour for text and icons that are part of non-interactive elements.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorContentStatic: 'var(--colorContentStatic)',

    /**
     * @description Text and icons that are part of non-interactive elements that should attract less attention.
     * @coreToken shade600 (color scheme light)
     * @rawValue #737373 (color scheme light)
     */
    colorContentStaticQuiet: 'var(--colorContentStaticQuiet)',

    /**
     * @description Text and icons that are part of non-interactive elements with reversed background and foreground colours.
     * @coreToken shade000 (color scheme light)
     * @rawValue #ffffff (color scheme light)
     */
    colorContentStaticInverse: 'var(--colorContentStaticInverse)',

    /**
     * @description Text and icons that signal something neutral.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorContentNeutral: 'var(--colorContentNeutral)',

    /**
     * @description Text and icons that signal something negative.
     * @coreToken red800 (color scheme light)
     * @rawValue #d61f1f (color scheme light)
     */
    colorContentNegative: 'var(--colorContentNegative)',

    /**
     * @description Text and icons that are part of a destructive interative element in a hover state.
     * @coreToken red900 (color scheme light)
     * @rawValue #a51d1d (color scheme light)
     */
    colorContentNegativeHover: 'var(--colorContentNegativeHover)',

    /**
     * @description Text and icons that signal something negative. Only use on colorBackgroundNegativeQuiet.
     * @coreToken red900 (color scheme light)
     * @rawValue #a51d1d (color scheme light)
     */
    colorContentNegativeLoud: 'var(--colorContentNegativeLoud)',

    /**
     * @description Text and icons that signal something has gone well.
     * @coreToken green800 (color scheme light)
     * @rawValue #2c8354 (color scheme light)
     */
    colorContentPositive: 'var(--colorContentPositive)',

    /**
     * @description Text and icons that signal something has gone well. Only use on colorBackgroundPositiveQuiet.
     * @coreToken green900 (color scheme light)
     * @rawValue #255937 (color scheme light)
     */
    colorContentPositiveLoud: 'var(--colorContentPositiveLoud)',

    /**
     * @description Text and icons that signal something requires urgent attention.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorContentWarning: 'var(--colorContentWarning)',

    /**
     * @description Text and icons that signal something requires urgent attention. Only use on colorBackgroundWarningQuiet.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorContentWarningLoud: 'var(--colorContentWarningLoud)',

    /**
     * @description Text and icons that signal important information.
     * @coreToken blue800 (color scheme light)
     * @rawValue #286ecc (color scheme light)
     */
    colorContentInfo: 'var(--colorContentInfo)',

    /**
     * @description Text and icons that signal important information. Only use on colorBackgroundInfoQuiet.
     * @coreToken blue900 (color scheme light)
     * @rawValue #27589b (color scheme light)
     */
    colorContentInfoLoud: 'var(--colorContentInfoLoud)',

    /**
     * @description Text and icons that signal something new in the product.
     * @coreToken purple700 (color scheme light)
     * @rawValue #7363c5 (color scheme light)
     */
    colorContentDiscover: 'var(--colorContentDiscover)',

    /**
     * @description Text and icons that signal something new in the product. Only use on colorBackgroundDiscoverQuiet.
     * @coreToken purple900 (color scheme light)
     * @rawValue #43387a (color scheme light)
     */
    colorContentDiscoverLoud: 'var(--colorContentDiscoverLoud)',

    /**
     * @description Icons used to signal an expense belonging to the 'entertainment' category
     * @rawValue #bc69a1 (color scheme light)
     */
    colorContentCategoryEntertainment: 'var(--colorContentCategoryEntertainment)',

    /**
     * @description Icons used to signal an expense belonging to the 'equipment' category
     * @rawValue #d87038 (color scheme light)
     */
    colorContentCategoryEquipment: 'var(--colorContentCategoryEquipment)',

    /**
     * @description Icons used to signal an expense belonging to the 'marketing' category
     * @rawValue #8374ca (color scheme light)
     */
    colorContentCategoryMarketing: 'var(--colorContentCategoryMarketing)',

    /**
     * @description Icons used to signal an expense belonging to the 'meals and drinks' category
     * @rawValue #3a9e59 (color scheme light)
     */
    colorContentCategoryMealsAndDrinks: 'var(--colorContentCategoryMealsAndDrinks)',

    /**
     * @description Icons used to signal an expense belonging to the 'office' category
     * @rawValue #598fd7 (color scheme light)
     */
    colorContentCategoryOffice: 'var(--colorContentCategoryOffice)',

    /**
     * @description Icons used to signal an expense belonging to the 'phone and internet' category
     * @rawValue #009eab (color scheme light)
     */
    colorContentCategoryPhoneAndInternet: 'var(--colorContentCategoryPhoneAndInternet)',

    /**
     * @description Icons used to signal an expense belonging to the 'software' category
     * @rawValue #a78f00 (color scheme light)
     */
    colorContentCategorySoftware: 'var(--colorContentCategorySoftware)',

    /**
     * @description Icons used to signal an expense belonging to the 'travel' category
     * @rawValue #cf707e (color scheme light)
     */
    colorContentCategoryTravel: 'var(--colorContentCategoryTravel)',

    /**
     * @description Icons used to signal an expense belonging to the 'service' category
     * @rawValue #a67ec6 (color scheme light)
     */
    colorContentCategoryService: 'var(--colorContentCategoryService)',

    /**
     * @description Icons used to signal an expense belonging to the 'supplies' category
     * @rawValue #7b9849 (color scheme light)
     */
    colorContentCategorySupplies: 'var(--colorContentCategorySupplies)',

    /**
     * @description Icons used to signal an expense belonging to the 'other' category
     * @coreToken shade600 (color scheme light)
     * @rawValue #737373 (color scheme light)
     */
    colorContentCategoryOther: 'var(--colorContentCategoryOther)',

    /**
     * @description Icons used to signal an expense belonging to no category
     * @coreToken shade600 (color scheme light)
     * @rawValue #737373 (color scheme light)
     */
    colorContentCategoryNone: 'var(--colorContentCategoryNone)',

    /**
     * @description Icons used to signal an expense whose category isn't known
     * @coreToken shade600 (color scheme light)
     * @rawValue #737373 (color scheme light)
     */
    colorContentCategoryUnknown: 'var(--colorContentCategoryUnknown)',

    /**
     * @description Default colour for backgrounds of interactive elements.
     * @coreToken shade000 (color scheme light)
     * @rawValue #ffffff (color scheme light)
     */
    colorBackgroundInteractive: 'var(--colorBackgroundInteractive)',

    /**
     * @description Backgrounds of relatively large interactive elements.
     * @coreToken shade100 (color scheme light)
     * @rawValue #fafafa (color scheme light)
     */
    colorBackgroundInteractiveLoud: 'var(--colorBackgroundInteractiveLoud)',

    /**
     * @description Backgrounds of relatively small interactive elements placed on top of loud or coloured backgrounds.
     * @rawValue rgba(0,0,0,0.05) (color scheme light)
     */
    colorBackgroundInteractiveLouder: 'var(--colorBackgroundInteractiveLouder)',

    /**
     * @description Backgrounds of relatively small interactive elements in a hover state.
     * @coreToken shade300 (color scheme light)
     * @rawValue #ececec (color scheme light)
     */
    colorBackgroundInteractiveHover: 'var(--colorBackgroundInteractiveHover)',

    /**
     * @description Backgrounds of relatively large interactive elements in a hover state.
     * @coreToken shade200 (color scheme light)
     * @rawValue #f5f5f5 (color scheme light)
     */
    colorBackgroundInteractiveQuietHover: 'var(--colorBackgroundInteractiveQuietHover)',

    /**
     * @description Backgrounds of interactive elements in a disabled state.
     * @coreToken shade200 (color scheme light)
     * @rawValue #f5f5f5 (color scheme light)
     */
    colorBackgroundInteractiveDisabled: 'var(--colorBackgroundInteractiveDisabled)',

    /**
     * @description Backgrounds of interactive elements with reversed background and foreground colours.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorBackgroundInteractiveInverse: 'var(--colorBackgroundInteractiveInverse)',

    /**
     * @description Backgrounds of interactive elements with reversed background and foreground colours in a hover state.
     * @coreToken shade900 (color scheme light)
     * @rawValue #000000 (color scheme light)
     */
    colorBackgroundInteractiveInverseHover: 'var(--colorBackgroundInteractiveInverseHover)',

    /**
     * @description Backgrounds of interactive elements in a selected state.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorBackgroundInteractiveSelected: 'var(--colorBackgroundInteractiveSelected)',

    /**
     * @description Backgrounds of interactive elements in a selected and hover state.
     * @coreToken shade900 (color scheme light)
     * @rawValue #000000 (color scheme light)
     */
    colorBackgroundInteractiveSelectedHover: 'var(--colorBackgroundInteractiveSelectedHover)',

    /**
     * @description Background of interactive elements placed on top of images or documents (e.g. invoices and receipts).
     * @rawValue rgba(0, 0, 0, 0.5) (color scheme light)
     */
    colorBackgroundInteractiveTransparent: 'var(--colorBackgroundInteractiveTransparent)',

    /**
     * @description Semi-transparent backgrounds of interactive elements in a hover state.
     * @rawValue rgba(0,0,0,0.08) (color scheme light)
     */
    colorBackgroundInteractiveTransparentHover: 'var(--colorBackgroundInteractiveTransparentHover)',

    /**
     * @description Default colour for backgrounds of non-interactive containers.
     * @coreToken shade000 (color scheme light)
     * @rawValue #ffffff (color scheme light)
     */
    colorBackgroundStatic: 'var(--colorBackgroundStatic)',

    /**
     * @description Backgrounds of non-interactive containers that should attract some attention.
     * @coreToken shade100 (color scheme light)
     * @rawValue #fafafa (color scheme light)
     */
    colorBackgroundStaticLoud: 'var(--colorBackgroundStaticLoud)',

    /**
     * @description Backgrounds of non-interactive containers that should attract significant attention.
     * @coreToken shade200 (color scheme light)
     * @rawValue #f5f5f5 (color scheme light)
     */
    colorBackgroundStaticLouder: 'var(--colorBackgroundStaticLouder)',

    /**
     * @description Backgrounds of non-interactive containers that should attract the most attention.
     * @coreToken shade300 (color scheme light)
     * @rawValue #ececec (color scheme light)
     */
    colorBackgroundStaticLoudest: 'var(--colorBackgroundStaticLoudest)',

    /**
     * @description The background upon which everything else is placed.
     * @coreToken shade000 (color scheme light)
     * @rawValue #ffffff (color scheme light)
     */
    colorBackgroundStaticPage: 'var(--colorBackgroundStaticPage)',

    /**
     * @description Semi-transparent backgrounds that sit between an overlay, such as a modal, and the rest of the underlying UI.
     * @rawValue rgba(0,0,0,0.5) (color scheme light)
     */
    colorBackgroundStaticBackdrop: 'var(--colorBackgroundStaticBackdrop)',

    /**
     * @description Backgrounds of non-interactive containers with reversed background and foreground colours.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorBackgroundStaticInverse: 'var(--colorBackgroundStaticInverse)',

    /**
     * @description Backgrounds of elements that signal something neutral.
     * @coreToken shade200 (color scheme light)
     * @rawValue #f5f5f5 (color scheme light)
     */
    colorBackgroundNeutral: 'var(--colorBackgroundNeutral)',

    /**
     * @description Backgrounds of relatively small elements that signal something negative.
     * @coreToken red700 (color scheme light)
     * @rawValue #e91c1c (color scheme light)
     */
    colorBackgroundNegative: 'var(--colorBackgroundNegative)',

    /**
     * @description Backgrounds of relatively large elements that signal something negative.
     * @coreToken red100 (color scheme light)
     * @rawValue #fff5f5 (color scheme light)
     */
    colorBackgroundNegativeQuiet: 'var(--colorBackgroundNegativeQuiet)',

    /**
     * @description Backgrounds of relatively large elements that signal something negative and are in a hover state.
     * @coreToken red200 (color scheme light)
     * @rawValue #fee6e6 (color scheme light)
     */
    colorBackgroundNegativeQuietHover: 'var(--colorBackgroundNegativeQuietHover)',

    /**
     * @description Backgrounds of relatively small elements that signal something negative and are in a hover state.
     * @coreToken red800 (color scheme light)
     * @rawValue #d61f1f (color scheme light)
     */
    colorBackgroundNegativeHover: 'var(--colorBackgroundNegativeHover)',

    /**
     * @description Backgrounds of relatively small elements that signal something has gone well.
     * @coreToken green800 (color scheme light)
     * @rawValue #2c8354 (color scheme light)
     */
    colorBackgroundPositive: 'var(--colorBackgroundPositive)',

    /**
     * @description Backgrounds of relatively large elements that signal something has gone well.
     * @coreToken green100 (color scheme light)
     * @rawValue #f4f9f6 (color scheme light)
     */
    colorBackgroundPositiveQuiet: 'var(--colorBackgroundPositiveQuiet)',

    /**
     * @description Backgrounds of relatively small elements that signal something requires urgent attention.
     * @coreToken yellow600 (color scheme light)
     * @rawValue #fbe360 (color scheme light)
     */
    colorBackgroundWarning: 'var(--colorBackgroundWarning)',

    /**
     * @description Backgrounds of relatively large elements that signal something requires urgent attention.
     * @coreToken yellow200 (color scheme light)
     * @rawValue #fcf9e4 (color scheme light)
     */
    colorBackgroundWarningQuiet: 'var(--colorBackgroundWarningQuiet)',

    /**
     * @description Backgrounds of relatively small elements that signal important information.
     * @coreToken blue800 (color scheme light)
     * @rawValue #286ecc (color scheme light)
     */
    colorBackgroundInfo: 'var(--colorBackgroundInfo)',

    /**
     * @description Backgrounds of relatively large elements that signal important information.
     * @coreToken blue100 (color scheme light)
     * @rawValue #f5f8fd (color scheme light)
     */
    colorBackgroundInfoQuiet: 'var(--colorBackgroundInfoQuiet)',

    /**
     * @description Backgrounds of relatively small elements that signal something new in the product.
     * @coreToken purple700 (color scheme light)
     * @rawValue #7363c5 (color scheme light)
     */
    colorBackgroundDiscover: 'var(--colorBackgroundDiscover)',

    /**
     * @description Backgrounds of relatively large elements that signal something new in the product.
     * @coreToken purple100 (color scheme light)
     * @rawValue #f8f7fd (color scheme light)
     */
    colorBackgroundDiscoverQuiet: 'var(--colorBackgroundDiscoverQuiet)',

    /**
     * @description Final brand color for backgrounds of elements that need to attract attention and are differentiated based on colour.
     * @coreToken shade300 (color scheme light)
     * @rawValue #ececec (color scheme light)
     */
    colorBackgroundPresentationalGray: 'var(--colorBackgroundPresentationalGray)',

    /**
     * @description Tertiary brand color for backgrounds of elements that need to attract attention and are differentiated based on colour.
     * @coreToken green400 (color scheme light)
     * @rawValue #c6ecd2 (color scheme light)
     */
    colorBackgroundPresentationalGreen: 'var(--colorBackgroundPresentationalGreen)',

    /**
     * @description Primary brand color for backgrounds of elements that need to attract attention and are differentiated based on colour.
     * @coreToken pink300 (color scheme light)
     * @rawValue #ffdee2 (color scheme light)
     */
    colorBackgroundPresentationalPink: 'var(--colorBackgroundPresentationalPink)',

    /**
     * @description Tertiary brand color for backgrounds of elements that need to attract attention and are differentiated based on colour.
     * @coreToken purple300 (color scheme light)
     * @rawValue #dcd7f4 (color scheme light)
     */
    colorBackgroundPresentationalPurple: 'var(--colorBackgroundPresentationalPurple)',

    /**
     * @description Secondary brand color for backgrounds of elements that need to attract attention and are differentiated based on colour.
     * @coreToken yellow400 (color scheme light)
     * @rawValue #fbefaf (color scheme light)
     */
    colorBackgroundPresentationalYellow: 'var(--colorBackgroundPresentationalYellow)',

    /**
     * @description Backgrounds upon which icons are placed from the 'entertainment' expense category
     * @rawValue #fcf5f9 (color scheme light)
     */
    colorBackgroundCategoryEntertainment: 'var(--colorBackgroundCategoryEntertainment)',

    /**
     * @description Backgrounds upon which icons are placed from the 'equipment' expense category
     * @rawValue #fff5f0 (color scheme light)
     */
    colorBackgroundCategoryEquipment: 'var(--colorBackgroundCategoryEquipment)',

    /**
     * @description Backgrounds upon which icons are placed from the 'marketing' expense category
     * @rawValue #f7f5ff (color scheme light)
     */
    colorBackgroundCategoryMarketing: 'var(--colorBackgroundCategoryMarketing)',

    /**
     * @description Backgrounds upon which icons are placed from the 'meals and drinks' expense category
     * @rawValue #ecfbf1 (color scheme light)
     */
    colorBackgroundCategoryMealsAndDrinks: 'var(--colorBackgroundCategoryMealsAndDrinks)',

    /**
     * @description Backgrounds upon which icons are placed from the 'office' expense category
     * @rawValue #f2f7fd (color scheme light)
     */
    colorBackgroundCategoryOffice: 'var(--colorBackgroundCategoryOffice)',

    /**
     * @description Backgrounds upon which icons are placed from the 'phone and internet' expense category
     * @rawValue #effbfc (color scheme light)
     */
    colorBackgroundCategoryPhoneAndInternet: 'var(--colorBackgroundCategoryPhoneAndInternet)',

    /**
     * @description Backgrounds upon which icons are placed from the 'software' expense category
     * @rawValue #fffadd (color scheme light)
     */
    colorBackgroundCategorySoftware: 'var(--colorBackgroundCategorySoftware)',

    /**
     * @description Backgrounds upon which icons are placed from the 'travel' expense category
     * @rawValue #fff5f6 (color scheme light)
     */
    colorBackgroundCategoryTravel: 'var(--colorBackgroundCategoryTravel)',

    /**
     * @description Backgrounds upon which icons are placed from the 'service' expense category
     * @rawValue #faf6fd (color scheme light)
     */
    colorBackgroundCategoryService: 'var(--colorBackgroundCategoryService)',

    /**
     * @description Backgrounds upon which icons are placed from the 'supplies' expense category
     * @rawValue #f0fade (color scheme light)
     */
    colorBackgroundCategorySupplies: 'var(--colorBackgroundCategorySupplies)',

    /**
     * @description Backgrounds upon which icons are placed from the 'other' expense category
     * @coreToken shade200 (color scheme light)
     * @rawValue #f5f5f5 (color scheme light)
     */
    colorBackgroundCategoryOther: 'var(--colorBackgroundCategoryOther)',

    /**
     * @description Backgrounds upon which icons are placed when they do not have an expense category
     * @coreToken shade200 (color scheme light)
     * @rawValue #f5f5f5 (color scheme light)
     */
    colorBackgroundCategoryNone: 'var(--colorBackgroundCategoryNone)',

    /**
     * @description Backgrounds upon which icons are placed when the category isn't known
     * @coreToken shade200 (color scheme light)
     * @rawValue #f5f5f5 (color scheme light)
     */
    colorBackgroundCategoryUnknown: 'var(--colorBackgroundCategoryUnknown)',

    /**
     * @description Background of relatively small UI elements that signal something new and actionable, such as a dot for new features or a count of items the user needs to take action on
     * @coreToken pink800 (color scheme light)
     * @rawValue #b2535f (color scheme light)
     * @experimental Please check in with Design Systems Team before using this token
     */
    colorBackgroundNotification: 'var(--colorBackgroundNotification)',

    /**
     * @description Default border colour of interactive elements.
     * @coreToken shade500 (color scheme light)
     * @rawValue #b3b3b3 (color scheme light)
     */
    colorBorderInteractive: 'var(--colorBorderInteractive)',

    /**
     * @description Borders of interactive elements that should attract less attention.
     * @coreToken shade300 (color scheme light)
     * @rawValue #ececec (color scheme light)
     */
    colorBorderInteractiveQuiet: 'var(--colorBorderInteractiveQuiet)',

    /**
     * @description Borders of interactive containers that should attract attention.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorBorderInteractiveLoud: 'var(--colorBorderInteractiveLoud)',

    /**
     * @description Borders of interactive elements in a hover state.
     * @coreToken shade900 (color scheme light)
     * @rawValue #000000 (color scheme light)
     */
    colorBorderInteractiveHover: 'var(--colorBorderInteractiveHover)',

    /**
     * @description Borders of interactive elements in a disabled state.
     * @coreToken shade200 (color scheme light)
     * @rawValue #f5f5f5 (color scheme light)
     */
    colorBorderInteractiveDisabled: 'var(--colorBorderInteractiveDisabled)',

    /**
     * @description Borders of interactive elements in a selected state.
     * @coreToken shade800 (color scheme light)
     * @rawValue #222222 (color scheme light)
     */
    colorBorderInteractiveSelected: 'var(--colorBorderInteractiveSelected)',

    /**
     * @description Borders of interactive elements in a selected and hover state.
     * @coreToken shade900 (color scheme light)
     * @rawValue #000000 (color scheme light)
     */
    colorBorderInteractiveSelectedHover: 'var(--colorBorderInteractiveSelectedHover)',

    /**
     * @description Borders of non-interactive containers.
     * @coreToken shade300 (color scheme light)
     * @rawValue #ececec (color scheme light)
     */
    colorBorderStatic: 'var(--colorBorderStatic)',

    /**
     * @description Borders or separators of non-interactive elements that should attract attention or are used on top of loud static backgrounds.
     * @coreToken shade400 (color scheme light)
     * @rawValue #cccccc (color scheme light)
     */
    colorBorderStaticLoud: 'var(--colorBorderStaticLoud)',

    /**
     * @description Borders of elements that signal something neutral.
     * @coreToken shade500 (color scheme light)
     * @rawValue #b3b3b3 (color scheme light)
     */
    colorBorderNeutral: 'var(--colorBorderNeutral)',

    /**
     * @description Borders of elements that signal something negative.
     * @coreToken red700 (color scheme light)
     * @rawValue #e91c1c (color scheme light)
     */
    colorBorderNegative: 'var(--colorBorderNegative)',

    /**
     * @description Borders of interactive elements that signal something negative and are in a hover state.
     * @coreToken red800 (color scheme light)
     * @rawValue #d61f1f (color scheme light)
     */
    colorBorderNegativeHover: 'var(--colorBorderNegativeHover)',

    /**
     * @description Borders of elements that signal something has gone well.
     * @coreToken green800 (color scheme light)
     * @rawValue #2c8354 (color scheme light)
     */
    colorBorderPositive: 'var(--colorBorderPositive)',

    /**
     * @description Borders of elements that signal something requires urgent attention.
     * @coreToken yellow700 (color scheme light)
     * @rawValue #dfc538 (color scheme light)
     */
    colorBorderWarning: 'var(--colorBorderWarning)',

    /**
     * @description Borders of elements that signal important information.
     * @coreToken blue800 (color scheme light)
     * @rawValue #286ecc (color scheme light)
     */
    colorBorderInfo: 'var(--colorBorderInfo)',

    /**
     * @description Borders of elements that signal something new in the product.
     * @coreToken purple700 (color scheme light)
     * @rawValue #7363c5 (color scheme light)
     */
    colorBorderDiscover: 'var(--colorBorderDiscover)',

    /**
     * @description Default focus ring colour.
     * @coreToken shade900 (color scheme light)
     * @rawValue #000000 (color scheme light)
     */
    colorShadowFocus: 'var(--colorShadowFocus)',

    /**
     * @description Focus ring colour for interactive elements in an invalid state.
     * @coreToken red700 (color scheme light)
     * @rawValue #e91c1c (color scheme light)
     */
    colorShadowFocusInvalid: 'var(--colorShadowFocusInvalid)',

    /**
     * @description Default shadow colour for elevations.
     * @rawValue rgba(0,0,0,0.1) (color scheme light)
     */
    colorShadowDepth: 'var(--colorShadowDepth)',

    /**
     * @description Shadow colour for subtle elevations.
     * @rawValue rgba(0,0,0,0.05) (color scheme light)
     */
    colorShadowDepthQuiet: 'var(--colorShadowDepthQuiet)',

    /**
     * @description Shadow colour for strong elevations.
     * @rawValue rgba(0,0,0,0.6) (color scheme light)
     */
    colorShadowDepthLoud: 'var(--colorShadowDepthLoud)',

    /**
     * @description Shadow that elevates elements from their bottom edge.
     * @coreToken 0 8px 12px -4px var(--colorShadowDepth) (color scheme light)
     * @rawValue 0 8px 12px -4px rgba(0,0,0,0.1) (color scheme light)
     */
    shadowElevate: 'var(--shadowElevate)',

    /**
     * @description Shadow that strongly elevates elements from their bottom edge.
     * @coreToken 0 2px 8px -6px var(--colorShadowDepthLoud) (color scheme light)
     * @rawValue 0 2px 8px -6px rgba(0,0,0,0.6) (color scheme light)
     */
    shadowElevateLoud: 'var(--shadowElevateLoud)',

    /**
     * @description Shadow that subtly elevates elements subtly from their bottom edge.
     * @coreToken 0 8px 12px -4px var(--colorShadowDepthQuiet) (color scheme light)
     * @rawValue 0 8px 12px -4px rgba(0,0,0,0.05) (color scheme light)
     */
    shadowElevateQuiet: 'var(--shadowElevateQuiet)',

    /**
     * @description Shadow that raises elements off the page from all edges.
     * @coreToken 0 6px 24px 0 var(--colorShadowDepth) (color scheme light)
     * @rawValue 0 6px 24px 0 rgba(0,0,0,0.1) (color scheme light)
     */
    shadowRaise: 'var(--shadowRaise)',

    /**
     * @description Shadow that subtly raises elements off the page from all edges.
     * @coreToken 0 3px 12px 0 var(--colorShadowDepth) (color scheme light)
     * @rawValue 0 3px 12px 0 rgba(0,0,0,0.1) (color scheme light)
     */
    shadowRaiseQuiet: 'var(--shadowRaiseQuiet)',

    /**
     * @description Default focus ring for interactive elements. However, use Telescope's `focusRing` utility for focus states, instead.
     * @coreToken 0 0 0 3px var(--colorShadowFocus) (color scheme light)
     * @rawValue 0 0 0 3px #000000 (color scheme light)
     */
    shadowFocusRegular: 'var(--shadowFocusRegular)',

    /**
     * @description Default focus ring for invalid interactive elements. However, use Telescope's `focusRing` utility for focus states, instead.
     * @coreToken 0 0 0 3px var(--colorShadowFocusInvalid) (color scheme light)
     * @rawValue 0 0 0 3px #e91c1c (color scheme light)
     */
    shadowFocusRegularInvalid: 'var(--shadowFocusRegularInvalid)',

    /**
     * @description Offset focus ring for interactive elements with a border resembling the focus ring. However, use Telescope's `focusRing` utility for focus states, instead.
     * @coreToken 0 0 0 6px var(--colorShadowFocus), 0 0 0 3px var(--shade000) (color scheme light)
     * @rawValue 0 0 0 6px #000000, 0 0 0 3px #ffffff (color scheme light)
     */
    shadowFocusOffset: 'var(--shadowFocusOffset)',

    /**
     * @description Offset focus ring for invalid interactive elements with a border resembling the focus ring. However, use Telescope's `focusRing` utility for focus states, instead.
     * @coreToken 0 0 0 6px var(--colorShadowFocusInvalid), 0 0 0 3px var(--shade000) (color scheme light)
     * @rawValue 0 0 0 6px #e91c1c, 0 0 0 3px #ffffff (color scheme light)
     */
    shadowFocusOffsetInvalid: 'var(--shadowFocusOffsetInvalid)',

    /**
     * @description Inset focus ring for interactive elements with no space around them. However, use Telescope's `focusRing` utility for focus states, instead.
     * @coreToken inset 0 0 0 3px var(--colorShadowFocus) (color scheme light)
     * @rawValue inset 0 0 0 3px #000000 (color scheme light)
     */
    shadowFocusInset: 'var(--shadowFocusInset)',

    /**
     * @description Inset focus ring for invalid interactive elements with no space around them. However, use Telescope's `focusRing` utility for focus states, instead.
     * @coreToken inset 0 0 0 3px var(--colorShadowFocusInvalid) (color scheme light)
     * @rawValue inset 0 0 0 3px #e91c1c (color scheme light)
     */
    shadowFocusInsetInvalid: 'var(--shadowFocusInsetInvalid)'
}

export default tokens
