import { ComponentExample, Features, Feature, RelatedComponents } from 'docs';
import { Loading, LoadingPage, LoadingOverlay } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  RelatedComponents,
  Loading,
  LoadingPage,
  LoadingOverlay,
  React
};