import { ComponentExample, DosAndDonts, Features, Feature, RelatedComponents } from 'docs';
import { Accordion, Text, List } from '@pleo-io/telescope';
import { CategoryEntertainment, CategoryMealsAndDrinks, CategoryTravel, CategoryNone, File } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  ComponentExample,
  DosAndDonts,
  Features,
  Feature,
  RelatedComponents,
  Accordion,
  Text,
  List,
  CategoryEntertainment,
  CategoryMealsAndDrinks,
  CategoryTravel,
  CategoryNone,
  File,
  React
};